import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  savedValue: null,
  savedLabel: null,
};

//Set initial client id and name dynamically
export const fetchClientInfo = createAsyncThunk("getClientInfo", async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}config`);
    const data = response.data;

    //Get enabled client id and name of enabled agency
    const foundObject = data.find(obj => obj.smart_venues);

    if (foundObject) {
      localStorage.setItem('value', foundObject.clientid);
      localStorage.setItem('label', foundObject.clientname);
      return { ...foundObject };
    } else {
      console.log("Client not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching client info:", error);
    throw error; // Rethrow the error to mark the thunk as rejected
  }
});

const sessionClientSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    sessionClientSaveValue: (state, action) => {
      state.savedValue = action.payload;
    },
    sessionClientSaveLabel: (state, action) => {
      state.savedLabel = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchClientInfo.fulfilled, (state, action) => {
      state.savedValue = action.payload.clientid;
      state.savedLabel = action.payload.clientname;
    });
  },
});

export const { sessionClientSaveValue, sessionClientSaveLabel } = sessionClientSlice.actions;

// Selector functions to retrieve the savedValue and savedLabel from the Redux store
export const selectSavedValue = (state) => state.sessionClient.savedValue;
export const selectSavedLabel = (state) => state.sessionClient.savedLabel;

export default sessionClientSlice.reducer;
