import React, { useState } from "react";
import { Icon, Button } from "../Component";

const NSComponent = ({ max, min, step = 0.1, outline, color, defaultVal, setValue }) => {
  const [localValue, setLocalValue] = useState(defaultVal);

  const addVal = (n) => {
    let newValue = parseFloat((localValue + n).toFixed(1));
    if (newValue <= max) {
      setLocalValue(newValue);
      setValue(newValue); // Update the parent component state
    }
  };

  const reduceVal = (n) => {
    let newValue = parseFloat((localValue - n).toFixed(1));
    if (newValue >= min) {
      setLocalValue(newValue);
      setValue(newValue); // Update the parent component state
    }
  };

  const handleChange = (e) => {
    let newValue = parseFloat(e.target.value);
    if (newValue >= min && newValue <= max) {
      setLocalValue(newValue);
      setValue(newValue); // Update the parent component state
    }
  };

  return (
    <div className="form-control-wrap number-spinner-wrap">
      <Button
        outline={outline}
        color={color}
        disabled={localValue === min}
        className="btn-icon number-spinner-btn number-minus"
        onClick={() => reduceVal(step)}
      >
        <Icon name="minus" />
      </Button>
      <input
        type="number"
        className="form-control number-spinner"
        value={localValue}
        onChange={handleChange}
        step={step}
        max={max}
        min={min}
      />
      <Button
        outline={outline}
        color={color}
        disabled={localValue === max}
        className="btn-icon number-spinner-btn number-plus"
        onClick={() => addVal(step)}
      >
        <Icon name="plus" />
      </Button>
    </div>
  );
};

export default NSComponent;
