import axios from "axios";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Polyline, useMap, Tooltip } from "react-leaflet";
import { icon } from "leaflet";
import { PreviewCard, UserAvatar } from "../../../../components/Component";
import { Button, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import busicon from "../../../../assets/images/greenBus.png";
import source from "../../../../assets/images/sourceAnimate.gif";
import destination from "../../../../assets/images/destinationAnimate.gif";
import DatePicker from "react-datepicker";
import Lottie from "lottie-react";
import mapLoading from "../../../../images/mapLoading.json";
import satellite from "../../../../images/satellite.json";
import Speedometer from "./Speedometer";


const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);
  return null;
};

const PlaybackMapTeltonika = ({ imei, date, durationSpeed, setIsEmpty, isPaused, setResetIcon, resetIcon }) => {

  const [loading, setLoading] = useState(false);
  const [positionIndex, setPositionIndex] = useState(0);
  const [positions, setPositions] = useState([]);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [speed, setSpeed] = useState(0);
  const [satelliteCnt, setSatelliteCnt] = useState(0);
  const [initialPosition, setInitialPosition] = useState(null);
  const [lastPosition, setLastPosition] = useState(null);

  const [srcName, setSrcName] = useState('');
  const [destName, setDestName] = useState('');

  const busIcon = icon({
    iconUrl: busicon,
    iconSize: [30, 30],
  });

  const sourceIcon = icon({
    iconUrl: source,
    iconSize: [50, 50],
  });

  const destinationIcon = icon({
    iconUrl: destination,
    iconSize: [50, 50],
  });

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fetchPlaceName = async (lat, lng, key) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=5&addressdetails=1`,
        {
          headers: {
            'User-Agent': '',
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();
      if (key === 'src') {
        if (data && data.display_name) {
          setSrcName(data.display_name);
        } else {
          setSrcName('Unknown Location');
        }
      }
      else if (key === 'dest') {
        if (data && data.display_name) {
          setDestName(data.display_name);
          console.log("Place Name:", data.display_name.split(',')[0]);
        } else {
          setDestName('Unknown Location');
        }
      }
    } catch (error) {
      console.error('Error fetching place name:', error);
      setSrcName('Unknown Location');
      setDestName('Unknown Location');
    }
  };

  useEffect(() => {
    const fetchMapData = async () => {
      setLoading(true);
      setPositions([]);
      try {
        const response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}gps/playback?date=${formatDate(
              date)}&imei=${imei}`
        );
        console.log("Playback response", response.data);

        setPositions(response.data);
        setInitialPosition(response.data[0]);
        setLastPosition(response.data[response.data.length - 1]);
        fetchPlaceName((response.data[0]).latitude, (response.data[0]).longitude, 'src');
        fetchPlaceName((response.data[response.data.length - 1]).latitude, (response.data[response.data.length - 1]).longitude, 'dest');
        if (response.data.length > 0)
          setIsEmpty(false);
        else
          setIsEmpty(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchMapData();
  }, []);

  useEffect(() => {
    if (positions.length > 0 && positionIndex < positions.length - 1 && resetIcon === false) {
      const startPoint = positions[positionIndex];
      const endPoint = positions[positionIndex + 1];
      const duration = durationSpeed;
      console.log("Speed", duration)
      if (!isPaused)
        animateMarker(startPoint, endPoint, duration);
    }
    else {
      if (initialPosition) {
        setResetIcon(true);
      }
    }
  }, [positions, positionIndex, isPaused]);

  const animateMarker = (startPoint, endPoint, duration) => {
    const startTime = Date.now();

    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = elapsedTime / duration;

      if (progress >= 1) {
        setPositionIndex((prevIndex) => prevIndex + 1);
      } else {
        const speedValue = Math.round(parseFloat(startPoint.speed) * 0.6)
        setSpeed(speedValue);
        setSatelliteCnt(startPoint.satellite);
        const lat = parseFloat(startPoint.latitude) + (parseFloat(endPoint.latitude) - parseFloat(startPoint.latitude)) * progress;
        const lng = parseFloat(startPoint.longitude) + (parseFloat(endPoint.longitude) - parseFloat(startPoint.longitude)) * progress;
        setMarkerPosition([lat, lng]);

        requestAnimationFrame(animate);
      }
    };

    animate();
  };

  return (
    <React.Fragment>
      {/* <PreviewCard>
                <ul className="list-inline">
                    <li>
                        <div className="form-control-wrap" style={{ width: '120px', position: "relative", zIndex: 1000 }}>
                            <DatePicker selected={updatedDate} onChange={setUpdatedDate} className="form-control date-picker" />
                        </div>
                    </li>
                    <li>
                    </li>
                </ul>
            </PreviewCard> */}
      <div className="leaflet-container pb-6">
        {loading ? (
          <div className="text-center mt-5">
            <Spinner color="primary" />
          </div>
        ) : (
          positions.length > 0 ?
            <MapContainer center={initialPosition ? [parseFloat(initialPosition.latitude), parseFloat(initialPosition.longitude)] : [0, 0]} zoom={14}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markerPosition && (
                <Marker position={markerPosition} icon={busIcon} />
              )}
              <Polyline positions={positions.map((pos) => [parseFloat(pos.latitude), parseFloat(pos.longitude)])} color="#0655c2" />

              {initialPosition && (
                <Marker position={[parseFloat(initialPosition.latitude), parseFloat(initialPosition.longitude)]} icon={sourceIcon}>
                  <Tooltip><b>Source:</b> {srcName}</Tooltip>
                </Marker>
              )}

              {lastPosition && (
                <Marker position={[parseFloat(lastPosition.latitude), parseFloat(lastPosition.longitude)]} icon={destinationIcon}>
                  <Tooltip><b>Destination:</b> {destName}</Tooltip>
                </Marker>
              )}

              {markerPosition && <RecenterAutomatically lat={markerPosition[0]} lng={markerPosition[1]} />}
              <div className="leaflet-top leaflet-right">
                <div className="leaflet-control leaflet-bar" style={{ border: "none" }}>
                  <div style={{ width: "100px", height: "100px" }}>
                    <Card className="border border-primary">
                      <div className="d-flex justify-content-center">
                        <Speedometer value={speed} />
                      </div>
                    </Card>
                  </div>
                  <div style={{ width: "100px", height: "80px", marginTop: "-16px" }}>
                    <Card className="border border-primary">
                      <div className="d-flex justify-content-center">
                        <div style={{ width: "60px", height: "60px" }}>
                          <Lottie animationData={satellite} loop autoplay speed={0.7} />
                        </div>
                      </div>
                      <div style={{ textAlign: 'center', marginTop: '-10px' }}>
                        <span style={{ fontSize: '16px' }}>{satelliteCnt}</span>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </MapContainer> :
            <>
              <div className="d-flex justify-content-center align-items-center mt-5">
                <div style={{ width: "180px", height: "180px" }}>
                  <Lottie animationData={mapLoading} loop autoplay speed={0.7} />
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-5">
                <div><span style={{ fontSize: "20px" }}>No Data Found</span></div>
              </div>
            </>
        )}
      </div>
    </React.Fragment>
  );
};

export default PlaybackMapTeltonika;
