import React, { useState } from "react";

const CommentWithReadMore = ({ comment, maxLength = 70, keyValue }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedComment =
    comment.length > maxLength && !isExpanded ? `${comment.substring(0, maxLength)}...` : comment;

  return (
    <div style={{ whiteSpace: "normal", wordWrap: "break-word", maxWidth: "280px", textAlign: keyValue === "FB" ? "justify" : "left" }}>
      {/* Change left or center alignment with keyValue if it is needed */}
      {truncatedComment}
      {comment.length > maxLength && (
        <span onClick={toggleReadMore} style={{ color: "#0C67E3", cursor: "pointer" }}>
          {isExpanded ? " Read Less" : " Read More"}
        </span> 
      )}
    </div>
  );
};

export default CommentWithReadMore;
