import React, { useEffect, useState } from "react";
import * as echarts from "echarts";

const TopPurposeChart = ({ dateRanges }) => {
  const [topPurposesData, setTopPurposesData] = useState(null);
  const [loading, setLoading] = useState(true);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchTopPurposesData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywisePurposeCount?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}`
        );
        const responseData = await response.json();
        console.log("Top Purpose Response data:", responseData);
        if (responseData && responseData.count && responseData.count.length === 0) {
          setTopPurposesData([]);
          setLoading(false);
        } else {
          const formattedData = responseData.count.map((item) => ({
            value: item.count,
            name: item.purpose,
          }));
          setTopPurposesData(formattedData);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchTopPurposesData();
  }, [dateRanges.start, dateRanges.end]);

  useEffect(() => {
    if (!topPurposesData || loading) return;

    const dom = document.getElementById("chart-container");
    const myChart = echarts.init(dom, null, {
      renderer: "canvas",
      useDirtyRect: false,
    });

    const option = {
      backgroundColor: "#ffffff",
      title: {
        text: "Customized Pie",
        left: "center",
        top: 20,
        textStyle: {
          color: "#ccc",
        },
      },
      tooltip: {
        trigger: "item",
      },
      visualMap: {
        show: false,
        min: 80,
        max: 600,
        inRange: {
          colorLightness: [0, 1],
        },
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: "55%",
          center: ["50%", "50%"],
          data: topPurposesData.sort((a, b) => a.value - b.value),
          roseType: "radius",
          label: {
            color: "rgba(255, 255, 255, 0.3)",
          },
          labelLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)",
            },
            smooth: 0.2,
            length: 10,
            length2: 20,
          },
          itemStyle: {
            color: "#c23531",
            shadowBlur: 200,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          animationType: "scale",
          animationEasing: "elasticOut",
          animationDelay: (idx) => Math.random() * 200,
        },
      ],
    };

    if (option && typeof option === "object") {
      myChart.setOption(option);
    }

    window.addEventListener("resize", myChart.resize);

    return () => {
      window.removeEventListener("resize", myChart.resize);
      myChart.dispose();
    };
  }, [topPurposesData, loading]);

  return (
    <div>
      <div id="chart-container" style={{ width: "100%", height: "400px" }}></div>
      {!loading && topPurposesData && topPurposesData.length === 0 && (
        <div className="text-center mt-2">No New and Existing Users</div>
      )}
    </div>
  );
};

export default TopPurposeChart;

//export default TopPurposeChart;
