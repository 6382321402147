import React, { useState, useEffect, useRef } from "react";
import "./total.css";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { user_id } from "../redux/userSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import CountUp from "react-countup";
import Lottie from "lottie-react";
import animationData from "../images/Ticketsanimation.json";
import DatePicker from "react-datepicker";
import { Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Card, TabContent, Modal, ModalBody, TabPane, CardTitle, CardBody, Badge } from "reactstrap";
import styled from "styled-components";
import backgroundImage from "../assets/images/beverage_background.png";
import { toast } from "react-toastify";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    BlockBetween,
    Button,
    PreviewCard,
    ReactDataTable,
    PaginationComponent,
    DataTablePagination,
} from "../components/Component";
import DataTable from "react-data-table-component";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { role } from "../redux/userSlice";

//Export packages
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from "react-moment";

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="custom-control custom-control-sm custom-checkbox notext">
        <input
            id={rest.name}
            type="checkbox"
            className="custom-control-input form-control"
            ref={ref}
            onClick={onClick}
            {...rest}
        />
        <label className="custom-control-label" htmlFor={rest.name} />
    </div>
));

const ExpandableRowComponent = ({ data }) => {

    return (
        <ul className="dtr-details p-2 border-bottom ml-1">
            {
                data.Status !== 0 &&
                <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
                    <span className="dtr-title">Updated Date</span>{" "}
                    <span className="dtr-data">
                        {data.Updateddate ? (
                            <Moment format="MMMM Do YYYY, h:mm a">{data.Updateddate}</Moment>
                        ) : (
                            "-"
                        )}
                    </span>
                </li>
            }
            <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
                <span style={{ marginRight: "1rem" }} className=" dtr-title">
                    Status
                </span>{" "}
                <span className="dtr-data">{(data.Status === 0 ? "Purchased" : (data.Status === 1 ? "Ready to Serve" : "Served"))}</span>
            </li>
            <li style={{ padding: "0.25rem" }}>
                <span style={{ marginRight: "1rem" }} className=" dtr-title">
                    Count
                </span>{" "}
                <span className="dtr-data">{data.Beveragecount}</span>
            </li>
        </ul>
    );
};

const Beverages = ({ className, selectableRows }) => {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [displayData, setdisplayData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [expandData, setExpandData] = useState(false);
    const [selectedData, setSelectedData] = useState("purchased");

    const logoUrl = process.env.REACT_APP_SIGN_IN;
    const clientValueLabel = localStorage.getItem("label");
    const userrole = useSelector(role);

    const filteredItems = displayData && displayData.filter((item) => {
        return (
            (item.Beveragecount !== null && item.Beveragecount !== "" && item.Beveragecount.toString().split(' ').join('').includes(searchText.trim().toLowerCase().split(' ').join(''))) ||
            (item.Emailaddress !== null && item.Emailaddress !== "" && item.Emailaddress.toLowerCase().split(' ').join('').includes(searchText.trim().toLowerCase().split(' ').join(''))) ||
            (item.Name !== null && item.Name !== "" && item.Name.toLowerCase().split(' ').join('').includes(searchText.trim().toLowerCase().split(' ').join('')))
        );
    });

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPageS, setRowsPerPage] = useState(7);

    //Dropdown
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Last Week");
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleDropdownChange = (value) => {
        setCurrentPage(1);
        setSelectedOption(value);
        setIsOpen(false);
        const today = new Date();
        let startDate = new Date();
        let endDate = new Date();

        switch (value) {
            case "Today":
                startDate = today;
                endDate = today;
                break;
            case "Last Week":
                startDate.setDate(today.getDate() - 7);
                break;
            case "Last Month":
                startDate.setMonth(today.getMonth() - 1);
                break;
            case "Last 3 Months":
                startDate.setMonth(today.getMonth() - 3);
                break;
            default:
                break;
        }
        setRangeDate({ start: startDate, end: endDate });
    };

    //Datepicker
    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - 7);

    const [rangeDate, setRangeDate] = useState({
        start: daysAgo,
        end: new Date(),
    });
    const onRangeChange = (dates) => {
        const [start, end] = dates;
        setRangeDate({ start: start, end: end });
        setCurrentPage(1);
    };
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    //Pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };


    //Search
    const changeSearchText = (e) => {
        setCurrentPage(1); //For filtering data even in different pages
        setSearchText(e.target.value);
    };

    //Export
    const exportSuccessMsg = () => {
        toast.dark("Chart Report Exported Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            icon: false,
        });
    };

    const exportNoDataAlert = () => {
        toast.error("There is no data available for export!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
        });
    };

    const beverageServeMsg = () => {
        toast.success("Beverage Served Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            icon: false,
        });
    };

    const exportCSV = () => {
        const fileName = 'Beverages';
        if (filteredItems.length === 0) {
            exportNoDataAlert();
            return;
        }

        const formattedItems = filteredItems.map(item => {
            return {
                ...item,
                dateTime: moment(item.Createddate).format('MMMM Do YYYY, h:mm a'),
                timestamp: moment(item.Updateddate).format('MMMM Do YYYY, h:mm a'),
            };
        });

        const exportType = exportFromJSON.types.csv;

        try {
            exportFromJSON({
                data: formattedItems,
                fileName: fileName,
                exportType: exportType
            });
            exportSuccessMsg();
        } catch (error) {
            console.error('Export failed:', error);
            exportNoDataAlert();
        }
    };
    const exportExcel = () => {
        const fileName = 'Beverages';
        if (filteredItems.length === 0) {
            exportNoDataAlert();
            return;
        }

        const formattedItems = filteredItems.map(item => {
            return {
                ...item,
                dateTime: moment(item.Createddate).format('MMMM Do YYYY, h:mm a'),
                timestamp: moment(item.Updateddate).format('MMMM Do YYYY, h:mm a'),
            };
        });

        const exportType = exportFromJSON.types.xls;

        try {
            exportFromJSON({
                data: formattedItems,
                fileName: fileName,
                exportType: exportType
            });
            exportSuccessMsg();
        } catch (error) {
            console.error('Export failed:', error);
            exportNoDataAlert();
        }
    };

    const exportPDF = (filteredItems) => {
        const fileName = `Beverages`;
        if (filteredItems.length === 0) {
            exportNoDataAlert();
            return;
        }
        const doc = new jsPDF("landscape");

        const logo = new Image();
        logo.crossOrigin = 'anonymous';
        logo.src = logoUrl;

        logo.onload = () => {
            const imgWidth = 30;
            const imgHeight = 30;
            const logoX = doc.internal.pageSize.width - imgWidth - 10;
            const logoY = 2;
            doc.addImage(logo, 'JPEG', logoX, logoY, imgWidth, imgHeight);

            doc.setFont('times', 'bold');
            doc.setFontSize(20);
            doc.setTextColor(255, 0, 0);
            { userrole === 3 ? doc.text(clientValueLabel, 10, 20) : doc.text("oktoberfest", 10, 20) }

            doc.setFont('times', 'normal');
            doc.setFontSize(16);
            doc.setTextColor(0);
            doc.text("Beverages", 10, 40);

            const columnHeaders = ["Name", "Email ID", "Beverage Name", "Count", "Created Date", "Price"];
            const tableData = filteredItems.map((item) => [
                item.Name !== null && item.Name !== "" ? item.Name : "-",
                item.Emailaddress !== null && item.Emailaddress !== "" ? item.Emailaddress : "-",
                item.Beveragename !== null && item.Beveragename !== "" ? item.Beveragename : "-",
                item.Beveragecount !== null && item.Beveragecount !== "" ? item.Beveragecount : "-",
                item.Beveragecount !== null && item.Beveragecount !== "" ? item.Beveragecount : "-",
                item.Createddate !== null && item.Createddate !== "" ? moment(item.Createddate).format("MMMM Do YYYY, h:mm a") : "-",
                item.ProductCost !== null && item.ProductCost !== "" ? "$ " + item.ProductCost.toFixed(2) : "-",
            ]);
            doc.autoTable({
                head: [columnHeaders],
                body: tableData,
                startY: 50
            });
            doc.save(fileName + ".pdf");
            exportSuccessMsg();
        };

        logo.onerror = () => {
            console.error('Failed to load logo image');

            const columnHeaders = ["Name", "Email ID", "Beverage Name", "Count", "Created Date", "Price"];
            const tableData = filteredItems.map((item) => [
                item.Name !== null && item.Name !== "" ? item.Name : "-",
                item.Emailaddress !== null && item.Emailaddress !== "" ? item.Emailaddress : "-",
                item.Beveragename !== null && item.Beveragename !== "" ? item.Beveragename : "-",
                item.Beveragecount !== null && item.Beveragecount !== "" ? item.Beveragecount : "-",
                item.Beveragecount !== null && item.Beveragecount !== "" ? item.Beveragecount : "-",
                item.Createddate !== null && item.Createddate !== "" ? moment(item.Createddate).format("MMMM Do YYYY, h:mm a") : "-",
                item.ProductCost !== null && item.ProductCost !== "" ? "$ " + item.ProductCost.toFixed(2) : "-",
            ]);
            doc.autoTable({
                head: [columnHeaders],
                body: tableData,
            });
            doc.save(fileName + ".pdf");
            exportSuccessMsg();
        };
    };

    const serveBeverageApi = async (id) => {
        const response = await fetch(`https://zig-zigs-api.zed-admin.com/Zigsmartv3/api/Beverage/UpdatebeverageServed?id=${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.ok) {
            beverageServeMsg();
        } else {
            console.log("Something went wrong");
        }
        setIsLoading(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (rangeDate.start && rangeDate.end) {
                    const response = await fetch(`https://zig-zigs-api.zed-admin.com/Zigsmartv3/api/Beverage/GetAllBeverage?Startdate=${formatDate(rangeDate.start)}&Enddate=${formatDate(rangeDate.end)}&ClientId=64`);
                    const responseData = await response.json();
                    const sortedData = responseData.sort((a, b) => new Date(b.Createddate) - new Date(a.Createddate));
                    setData(sortedData);
                    console.log("Response, ", sortedData);

                    const response2 = await fetch(`https://zig-zigs-api.zed-admin.com/Zigsmartv3/api/Beverage/GetValidatedBeverages?Startdate=${formatDate(rangeDate.start)}&Enddate=${formatDate(rangeDate.end)}&ClientId=64`);
                    const responseData2 = await response2.json();
                    const sortedData2 = responseData2.sort((a, b) => new Date(b.Createddate) - new Date(a.Createddate));
                    setData2(sortedData2);
                    console.log("Response, ", sortedData2);

                    if (selectedData === "purchased") {
                        setdisplayData(sortedData);
                    }
                    else if (selectedData === "readytoserve") {
                        setdisplayData(sortedData2);
                    }
                }
            }
            catch {
                console.log("error");
            }
            finally {
                setIsLoading(false);
            }
        }

        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);

    }, [selectedOption, selectedData, rangeDate.end, isLoading]);

    // useEffect(() => {
    //     if (selectedData === "purchased") {
    //         setdisplayData(data);
    //     }
    //     else if (selectedData === "readytoserve") {
    //         setdisplayData(data2);
    //     }
    // }, [selectedData])

    const columns = [
        {
            name: "Name",
            selector: (row) => row.Name.toLowerCase(),
            sortable: true,
            cell: (row) => row.Name ? row.Name : "-",
        },
        {
            name: "Email ID",
            selector: (row) => row.Emailaddress.toLowerCase(),
            sortable: true,
            cell: (row) => row.Emailaddress ? <div style={{ fontWeight: "bold", color: "#007bff" }}>{row.Emailaddress}</div> : "-",
            grow: 1.2
        },
        {
            name: "Beverage Name",
            selector: (row) => (row.Beveragename).toLowerCase(),
            sortable: true,
            cell: (row) => row.Beveragename ? row.Beveragename : "-",
        },
        {
            name: "Created Date",
            selector: (row) => row.Createddate,
            sortable: true,
            cell: (row) => (row.Createddate) ? <div style={{ fontWeight: "bold" }}>{moment(row.Createddate).format("MMM Do YYYY, h:mm a")}</div> : "-",
        },
        {
            name: "Price",
            selector: (row) => row.BeverageCost,
            sortable: true,
            cell: (row) => row.BeverageCost ? ("$ " + row.BeverageCost.toFixed(2)) : "-",
            grow: 0.4
        },
        {
            name: "",
            selector: (row) => row,
            cell: (row) =>
                row.Status === 1 ? (
                    <div style={{ cursor: "pointer" }} >
                        <Badge onClick={() => serveBeverageApi(row.Id)} color="success" >Serve</Badge>
                    </div>
                ) :
                    row.Status === 2 ? (
                        <div style={{ cursor: "pointer" }}>
                            <Badge color="danger" >Served</Badge>
                        </div>
                    ) :
                        null,
            grow: 0.3
        },
    ];

    return (
        <React.Fragment>
            <Head title="Homepage"></Head>
            <div
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    height: "280px",
                    paddingTop: "80px",
                }}
            >
                <BlockTitle page tag="h3">
                    <Title>Beverages</Title>
                </BlockTitle>
            </div>
            <Content>
                <div style={{ marginTop: "-50px" }}>
                    <Block>
                        <div className="flex-grow-1">
                            <Row>
                                <Col sm="12">
                                    {/* <label htmlFor="startDate">Date Range</label> */}
                                    <div className="d-flex align-items-end" style={{ marginBottom: 10 }}>
                                        <div style={{ textAlign: "right" }}>
                                            <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="dropdown-container">
                                                <DropdownToggle
                                                    color="primary"
                                                    className="dropdown-toggle"
                                                    style={{ paddingLeft: 20, paddingRight: 20 }}
                                                >
                                                    <Icon style={{ paddingRight: "5px" }} name="calender-date" />
                                                    {selectedOption}
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu">
                                                    <DropdownItem onClick={() => handleDropdownChange("Today")}>Today</DropdownItem>
                                                    <DropdownItem onClick={() => handleDropdownChange("Last Week")}>Last Week</DropdownItem>
                                                    <DropdownItem onClick={() => handleDropdownChange("Last Month")}>Last Month</DropdownItem>
                                                    <DropdownItem onClick={() => handleDropdownChange("Last 3 Months")}>
                                                        Last 3 Months
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                        <div className="ml-2" style={{ width: "200px" }}>
                                            <DatePicker
                                                selected={rangeDate.start}
                                                startDate={rangeDate.start}
                                                onChange={onRangeChange}
                                                endDate={rangeDate.end}
                                                selectsRange
                                                className="form-control date-picker"
                                                style={{ width: "200px" }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row className={"mt-2"}>
                            <Col md="6" sm="12">
                                <div>
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <div className="form-control-wrap">
                                                <div className="form-icon form-icon-right">
                                                    <Icon name="search"></Icon>
                                                </div>
                                                <input
                                                    style={{ width: "220px" }}
                                                    type="text"
                                                    className="form-control color-danger"
                                                    id="default-04"
                                                    placeholder="Search by Email ID"
                                                    // value={searchText}
                                                    onChange={(e) => changeSearchText(e)}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <UncontrolledDropdown className="pl-1">
                                                <DropdownToggle
                                                    outline
                                                    color="secondary"
                                                    className="dropdown-toggle dropdown-indicator btn-dim  flex"
                                                >
                                                    Beverage Status{" "}
                                                    {/* <span className="ml-1">
                              <Icon style={{ fontSize: "8px" }} name="downward-alt-fill"></Icon>
                            </span> */}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Select status</span>
                                                        </li>
                                                        <li className={selectedData === "purchased" && "active"} onClick={() => setSelectedData("purchased")}>
                                                            <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                                                Purchased
                                                            </a>
                                                        </li>
                                                        <li className={selectedData === "readytoserve" && "active"} onClick={() => setSelectedData("readytoserve")}>
                                                            <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                                                Ready to Serve
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                        <li>
                                            <UncontrolledDropdown className="pl-1">
                                                <DropdownToggle className="dropdown-toggle flex">
                                                    <div className="custom-control custom-control-sm custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            checked={expandData}
                                                            onClick={() => { setExpandData(!expandData) }}
                                                            className="custom-control-input form-control"
                                                            id="expand-data"
                                                        />
                                                        <label className="custom-control-label" htmlFor="expand-data">
                                                            Expand data
                                                        </label>
                                                    </div>
                                                </DropdownToggle>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="6" sm="12" className={"text-right"}>
                                <div className="dt-export-buttons d-flex align-center justify-content-end">
                                    <div className="dt-export-title d-none d-md-inline-block">Export</div>
                                    <div className="dt-buttons btn-group flex-wrap">
                                        <button
                                            className="btn btn-secondary buttons-csv buttons-html5"
                                            type="button"
                                            onClick={() => exportCSV(filteredItems)}
                                        >
                                            <span>CSV</span>
                                        </button>{" "}
                                        <button
                                            className="btn btn-secondary buttons-excel buttons-html5"
                                            type="button"
                                            onClick={() => exportExcel(filteredItems)}
                                        >
                                            <span>Excel</span>
                                        </button>{" "}
                                        <button
                                            className="btn btn-secondary buttons-pdf buttons-html5"
                                            type="button"
                                            onClick={() => exportPDF(filteredItems)}
                                        >
                                            <span>PDF</span>
                                        </button>{" "}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="flex-grow-1">
                            <div>
                                <ul className="nk-block-tools g-3">
                                    <li>
                                        <div className="form-control-wrap">
                                            <div className="form-icon form-icon-right">
                                                <Icon name="search"></Icon>
                                            </div>
                                            <input
                                                style={{ width: "220px" }}
                                                type="text"
                                                className="form-control color-danger"
                                                id="default-04"
                                                placeholder="Search"
                                                // value={searchText}
                                                onChange={(e) => changeSearchText(e)}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <UncontrolledDropdown className="pl-1">
                                            <DropdownToggle className="dropdown-toggle flex">
                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={expandData}
                                                        onClick={() => { setExpandData(!expandData) }}
                                                        className="custom-control-input form-control"
                                                        id="expand-data"
                                                    />
                                                    <label className="custom-control-label" htmlFor="expand-data">
                                                        Expand data
                                                    </label>
                                                </div>
                                            </DropdownToggle>
                                        </UncontrolledDropdown>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="dt-export-buttons d-flex align-center">
                            <div className="dt-export-title d-none d-md-inline-block">Export</div>
                            <div className="dt-buttons btn-group flex-wrap">
                                <button
                                    className="btn btn-secondary buttons-csv buttons-html5"
                                    type="button"
                                    onClick={() => exportCSV(filteredItems)}
                                >
                                    <span>CSV</span>
                                </button>{" "}
                                <button
                                    className="btn btn-secondary buttons-excel buttons-html5"
                                    type="button"
                                    onClick={() => exportExcel(filteredItems)}
                                >
                                    <span>Excel</span>
                                </button>{" "}
                                <button
                                    className="btn btn-secondary buttons-pdf buttons-html5"
                                    type="button"
                                    onClick={() => exportPDF(filteredItems)}
                                >
                                    <span>PDF</span>
                                </button>{" "}
                            </div>
                        </div>
                    </div> */}
                        {isLoading ?
                            <Card className="mt-2">
                                <div className="text-center p-4">
                                    <Spinner color="primary" />
                                </div>
                            </Card>
                            :
                            <>
                                <DataTable
                                    data={filteredItems}
                                    columns={columns}
                                    noDataComponent={<div className="pt-5 pb-5 text-center">No data found</div>}
                                    sortIcon={
                                        <div>
                                            <span>&darr;</span>
                                            <span>&uarr;</span>
                                        </div>
                                    }
                                    selectableRows={selectableRows}
                                    selectableRowsComponent={CustomCheckbox}
                                    expandableRowsComponent={ExpandableRowComponent}
                                    expandableRows={true}
                                    expandableRowExpanded={() => expandData}
                                    defaultSortField="dateTime"
                                    pagination
                                    paginationTotalRows={filteredItems.length}
                                    paginationPerPage={rowsPerPageS}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
                                        <DataTablePagination
                                            customItemPerPage={rowsPerPageS}
                                            itemPerPage={rowsPerPage}
                                            totalItems={rowCount}
                                            paginate={onChangePage}
                                            currentPage={currentPage}
                                            onChangeRowsPerPage={onChangeRowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />
                                    )}
                                ></DataTable>
                            </>
                        }
                    </Block>
                </div>
            </Content>
        </React.Fragment>
    );

};


const Title = styled.h3`
  font-size: 112px;
  font-weight: 900;
  padding-left: 32px;
`;


export default Beverages;
