import React, { useState, useEffect } from "react";
import "./total.css";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { username } from "../redux/userSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card } from "reactstrap";
import { toast } from "react-toastify";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  BlockBetween,
  PaginationComponent,
} from "../components/Component";
import DataTable from "react-data-table-component";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { role } from "../redux/userSlice";

//Export packages
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CommentWithReadMore from "../components/table/ReadMoreComp";

const Feedback = ({ className, selectableRows }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const logoUrl = process.env.REACT_APP_SIGN_IN;
  const clientValueLabel = localStorage.getItem("label");
  const userrole = useSelector(role);
  const user = useSelector(username);

  //Filtration
  const filteredItems =
    data &&
    data.filter((item) => {
      const formattedCreatDate =
        item.created_date !== null && item.created_date !== ""
          ? moment(item.created_date).format("MMMM Do YYYY, h:mm a")
          : "";

      return (
        (item.user_id !== null && item.user_id !== "" && item.user_id.toString().includes(searchText.toLowerCase())) ||
        (item.user_name !== null &&
          item.user_name !== "" &&
          item.user_name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.star_rating !== null && item.star_rating !== "" && item.star_rating.includes(searchText.toLowerCase())) ||
        (item.comments !== null &&
          item.comments !== "" &&
          item.comments.toLowerCase().includes(searchText.toLowerCase())) ||
        formattedCreatDate.toLowerCase().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))
      );
    });

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = filteredItems && filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //Dropdown
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Last Week");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleDropdownChange = (value) => {
    setCurrentPage(1);
    setSelectedOption(value);
    setIsOpen(false);
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (value) {
      case "Today":
        startDate = today;
        endDate = today;
        break;
      case "Last Week":
        startDate.setDate(today.getDate() - 7);
        break;
      case "Last Month":
        startDate.setMonth(today.getMonth() - 1);
        break;
      case "Last 3 Months":
        startDate.setMonth(today.getMonth() - 3);
        break;
      default:
        break;
    }
    setRangeDate({ start: startDate, end: endDate });
  };

  //Datepicker
  const daysAgo = new Date();
  daysAgo.setDate(daysAgo.getDate() - 7);

  const [rangeDate, setRangeDate] = useState({
    start: daysAgo,
    end: new Date(),
  });
  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });
    setCurrentPage(1);
  };
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  //Search
  const changeSearchText = (e) => {
    setCurrentPage(1); //For filtering data even in different pages
    setSearchText(e.target.value);
  };

  //Export
  const exportSuccessMsg = () => {
    toast.dark("Chart Report Exported Successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      icon: false,
    });
  };

  const exportNoDataAlert = () => {
    toast.error("There is no data available for export!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };

  const exportCSV = (filteredItems) => {
    const fileName = `Feedback [${formatDate(rangeDate.start)} - ${formatDate(rangeDate.end)}]`;
    if (filteredItems.length === 0) {
      exportNoDataAlert();
      return;
    }
    const csvData = [];
    // Push headers to CSV data
    csvData.push(["User ID, User Name", "Created Date", "Rating", "Comments"]);

    // Push row data to CSV data
    filteredItems.forEach((item) => {
      const rowData = [
        item.user_id !== null && item.user_id !== "" ? item.user_id : "-",
        item.user_name !== null && item.user_name !== "" ? item.user_name : "-",
        item.created_date !== null && item.created_date !== ""
          ? moment(item.created_date).format("MMMM Do YYYY, h:mm a")
          : "-",
        item.star_rating !== null && item.star_rating !== "" ? item.star_rating : "-",
        item.comments !== null && item.comments !== "" ? item.comments : "-",
      ];
      csvData.push(rowData);
    });

    // Convert CSV data to string
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Download CSV file
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    exportSuccessMsg();
  };

  const exportExcel = (filteredItems) => {
    const fileName = `Feedback [${formatDate(rangeDate.start)} - ${formatDate(rangeDate.end)}]`;
    if (filteredItems.length === 0) {
      exportNoDataAlert();
      return;
    }
    const exportType = exportFromJSON.types.xls;
    const modifiedData = filteredItems.map((item) => ({
      "User ID": item.user_id !== null && item.user_id !== "" ? item.user_id : "-",
      "User Name": item.user_name !== null && item.user_name !== "" ? item.user_name : "-",
      "Created Date":
        item.created_date !== null && item.created_date !== ""
          ? moment(item.created_date).format("MMMM Do YYYY, h:mm a")
          : "-",
      Rating: item.star_rating !== null && item.star_rating !== "" ? item.star_rating : "-",
      Comments: item.comments !== null && item.comments !== "" ? item.comments : "-",
    }));
    exportFromJSON({ data: modifiedData, fileName: fileName, exportType });
    exportSuccessMsg();
  };

  const exportPDF = (filteredItems) => {
    const fileName = `Feedback [${formatDate(rangeDate.start)} - ${formatDate(rangeDate.end)}]`;
    if (filteredItems.length === 0) {
      exportNoDataAlert();
      return;
    }
    const doc = new jsPDF("landscape");

    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = logoUrl;

    logo.onload = () => {
      const imgWidth = 30;
      const imgHeight = 30;
      const logoX = doc.internal.pageSize.width - imgWidth - 10;
      const logoY = 2;
      doc.addImage(logo, "JPEG", logoX, logoY, imgWidth, imgHeight);

      doc.setFont("times", "bold");
      doc.setFontSize(20);
      doc.setTextColor(255, 0, 0);
      {
        userrole === 3 ? doc.text(clientValueLabel, 10, 20) : doc.text(user, 10, 20);
      }

      doc.setFont("times", "normal");
      doc.setFontSize(16);
      doc.setTextColor(0);
      doc.text("Feedback", 10, 40);

      const columnHeaders = ["User ID", "User Name", "Created Date", "Rating", "Comments"];
      const tableData = filteredItems.map((item) => [
        item.user_id !== null && item.user_id !== "" ? item.user_id : "-",
        item.user_name !== null && item.user_name !== "" ? item.user_name : "-",
        item.created_date !== null && item.created_date !== ""
          ? moment(item.created_date).format("MMMM Do YYYY, h:mm a")
          : "-",
        item.star_rating !== null && item.star_rating !== "" ? item.star_rating : "-",
        item.comments !== null && item.comments !== "" ? item.comments : "-",
      ]);
      doc.autoTable({
        head: [columnHeaders],
        body: tableData,
        startY: 50,
      });
      doc.save(fileName + ".pdf");
      exportSuccessMsg();
    };

    logo.onerror = () => {
      console.error("Failed to load logo image");

      const columnHeaders = ["User ID", "User Name", "Created Date", "Rating", "Comments"];
      const tableData = filteredItems.map((item) => [
        item.user_id !== null && item.user_id !== "" ? item.user_id : "-",
        item.user_name !== null && item.user_name !== "" ? item.user_name : "-",
        item.created_date !== null && item.created_date !== ""
          ? moment(item.created_date).format("MMMM Do YYYY, h:mm a")
          : "-",
        item.star_rating !== null && item.star_rating !== "" ? item.star_rating : "-",
        item.comments !== null && item.comments !== "" ? item.comments : "-",
      ]);
      doc.autoTable({
        head: [columnHeaders],
        body: tableData,
      });
      doc.save(fileName + ".pdf");
      exportSuccessMsg();
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rangeDate.start && rangeDate.end) {
          const response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}reviews?startDate=${formatDate(
              rangeDate.start
            )}&endDate=${formatDate(rangeDate.end)}`
          );

          setData(response.data.data);
          console.log("Response, ", response.data.data);
        }
      } catch {
        console.log("error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedOption, rangeDate.end]);

  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>  
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Feedback
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="flex-grow-1">
              <Row>
                <Col sm="12">
                  {/* <label htmlFor="startDate">Date Range</label> */}
                  <div className="d-flex align-items-end" style={{ marginBottom: 25 }}>
                    <div style={{ textAlign: "right" }}>
                      <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="dropdown-container">
                        <DropdownToggle
                          color="primary"
                          className="dropdown-toggle"
                          style={{ paddingLeft: 20, paddingRight: 20 }}
                        >
                          <Icon style={{ paddingRight: "5px" }} name="calender-date" />
                          {selectedOption}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu">
                          <DropdownItem onClick={() => handleDropdownChange("Today")}>Today</DropdownItem>
                          <DropdownItem onClick={() => handleDropdownChange("Last Week")}>Last Week</DropdownItem>
                          <DropdownItem onClick={() => handleDropdownChange("Last Month")}>Last Month</DropdownItem>
                          <DropdownItem onClick={() => handleDropdownChange("Last 3 Months")}>
                            Last 3 Months
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div className="ml-2" style={{ width: "200px" }}>
                      <DatePicker
                        selected={rangeDate.start}
                        startDate={rangeDate.start}
                        onChange={onRangeChange}
                        endDate={rangeDate.end}
                        selectsRange
                        className="form-control date-picker"
                        style={{ width: "200px" }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dt-export-buttons d-flex align-center mb-2">
              <div className="dt-export-title d-none d-md-inline-block">Export</div>
              <div className="dt-buttons btn-group flex-wrap">
                <button
                  className="btn btn-secondary buttons-csv buttons-html5"
                  type="button"
                  onClick={() => exportCSV(filteredItems)}
                >
                  <span>CSV</span>
                </button>{" "}
                <button
                  className="btn btn-secondary buttons-excel buttons-html5"
                  type="button"
                  onClick={() => exportExcel(filteredItems)}
                >
                  <span>Excel</span>
                </button>{" "}
                <button
                  className="btn btn-secondary buttons-pdf buttons-html5"
                  type="button"
                  onClick={() => exportPDF(filteredItems)}
                >
                  <span>PDF</span>
                </button>{" "}
              </div>
            </div>
          </div>
          <Card className="px-2">
            <div className="mt-2">
              <ul className="nk-block-tools g-3">
                <li>
                  <div className="form-control-wrap">
                    <div className="form-icon form-icon-right">
                      <Icon name="search"></Icon>
                    </div>
                    <input
                      style={{ width: "220px" }}
                      type="text"
                      className="form-control"
                      id="default-04"
                      placeholder="Search"
                      // value={searchText}
                      onChange={(e) => changeSearchText(e)}
                    />
                  </div>
                </li>
              </ul>
            </div>
            {isLoading ? (
              <div className="text-center m-3">
                <Spinner color="primary" />
              </div>
            ) : (
              <>
                <DataTable
                  data={currentItems}
                  columns={columns}
                  className={className}
                  selectableRows={selectableRows}
                  noDataComponent={<div className="p-2">There are no records found</div>}
                  sortIcon={
                    <div>
                      <span>&darr;</span>
                      <span>&uarr;</span>
                    </div>
                  }
                  defaultSortField="user_name"
                  defaultSortAsc={false}
                  {...(currentPage && { currentPage })}
                />
                {filteredItems && (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={filteredItems && filteredItems.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                )}
              </>
            )}
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

const StarRating = ({ rating }) => {
  return (
    <ReactStars count={5} value={rating} size={24} edit={false} isHalf={true} activeColor="#ffd700" color="#dcdcdc" />
  );
};

const columns = [
  {
    name: "User ID",
    selector: (row) => (row.user_id ? row.user_id : "-"),
    sortable: true,
    grow: 0.2,
  },
  {
    name: "User Name",
    selector: (row) => (row.user_name ? row.user_name : "-"),
    sortable: true,
    grow: 0.5,
  },
  {
    name: "Created Date",
    selector: (row) =>
      moment(row.created_date).format("MMMM Do YYYY, h:mm a")
        ? moment(row.created_date).format("MMMM Do YYYY, h:mm a")
        : "-",
    sortable: true,
    grow: 0.4,
  },
  {
    name: "Rating",
    selector: (row) => (row.star_rating ? row.star_rating : "-"),
    sortable: true,
    cell: (row) => <StarRating rating={row.star_rating} />,
    grow: 0.3,
  },
  //   {
  //     name: "Comments",
  //     selector: (row) => (
  //       <div style={{ whiteSpace: "normal", wordWrap: "break-word", maxWidth: "250px", textAlign: "justify" }}>
  //         {row.comments ? row.comments : "-"}
  //       </div>
  //     ),
  //     sortable: true,
  //     grow: 0.5,
  //   },
  {
    name: "Comments",
    selector: (row) => <CommentWithReadMore comment={row.comments ? row.comments : "-"} keyValue="FB"/>,
    grow: 0.7,
  },
];

export default Feedback;
