/* eslint-disable no-sparse-arrays */

const menu = [
  {
    heading: "Dashboards",
  },
  {
    icon: "dashboard",
    text: "Home",
    link: "/home",
    page: 31,
  },
  {
    icon: "cpu",
    text: "Devices",
    page: 1,
    subMenu: [
      {
        icon: "cpu",
        text: "Payment Devices",
        link: "/devices",
      },
      {
        icon: "plus-round-fill",
        text: "Add New Device",
        link: "/add-device",
      },
      {
        icon: "ticket-fill",
        text: "Add Validator",
        link: "add-validator",
      },
      {
        icon: "property-alt",
        text: "Device Setup",
        link: "/device-setup",
        page: 28,
      },
    ],
  },
  {
    icon: "cc-alt2-fill",
    text: "Evolution Configuration",
    link: "/evo-config",
    page: 32,
  },
  {
    icon: "cc-alt2-fill",
    text: "Your Agency",
    page: 3,
    subMenu: [
      {
        icon: "cc-alt2-fill",
        text: "City Setup (GTFS)",
        link: "/gtfs",
        page: 3,
      },
      {
        icon: "user-list-fill",
        text: "Agency Setup",
        link: "/client-setup",
        page: 5,
      },
      {
        icon: "contact-fill",
        text: "Ticketing Setup",
        link: "/museum-data",
        page: 12,
      },
      {
        icon: "clipboard",
        text: "Bus Schedules",
        link: "/run-cutting-scheduler",
        page: 30,
      },
      {
        icon: "clipboard",
        text: "Driver Schedules",
        link: "/driver-schedule",
        page: 30,
      },
      // {
      //   icon: "user-alt",
      //   text: "Riders",
      //   link: "/visitors",
      //   page: 23,
      // },
    ],
  },
  {
    icon: "cc-alt2-fill",
    text: "Bus Schedules setup",
    page: 30,
    subMenu: [
      {
        icon: "clipboard",
        text: "Routes",
        link: "/routes",
        page: 30,
      },
      {
        icon: "clipboard",
        text: "Bus Schedules",
        link: "/run-cutting-scheduler",
        page: 30,
      },
      {
        icon: "clipboard",
        text: "Route schedules",
        link: "/driver-schedule",
        page: 30,
      },
      // {
      //   icon: "clipboard",
      //   text: "Master Schedules",
      //   link: "/create-master",
      //   page: 30,
      // },
    ],
  },
  {
    icon: "user-list-fill",
    text: "Agency Approval",
    link: "/clients",
    page: 4,
  },
  // {
  //   icon: "coffee",
  //   text: "Beverage",
  //   link: "/Beverage-c1",
  //   page: 6,
  // },
  localStorage.getItem("email") === "oktoberfest@zed.digital" ?
    {
      icon: "ticket-fill",
      text: "Tickets",
      link: "/Tickets",
      page: 7,
    } :
    {
      icon: "ticket-fill",
      text: "Fixed Route Tickets",
      link: "/Tickets",
      page: 7,
    },
  {
    icon: "ticket-plus-fill",
    text: "On Demand Tickets",
    link: "/microtickets",
    page: 42,
  },
  {
    icon: "calendar-booking",
    text: "On Demand Bookings",
    link: "/microticketsBooking",
    page: 69,
  },
  {
    icon: "cc-alt2-fill",
    text: "All Agencies",
    link: "/all-venues",
    page: 33,
  },
  {
    icon: "ticket-fill",
    text: "Tickets Booth",
    link: "/tickets-booth",
    page: 35,
  },

  {
    icon: "coffee",
    text: "Add Products",
    link: "/add-beverages",
    page: 9,
  },
  {
    icon: "map",
    text: "Transit Data",
    link: "/transit-data",
    page: 10,
  },
  {
    icon: "map",
    text: "Transit Approval",
    link: "/transit-approval",
    page: 11,
  },

  ,
  // {
  //   icon: "clipboard",
  //   text: "Run Cutting",
  //   link: "/run-cutting",
  //   page: 13,
  // },
  {
    icon: "map",
    text: "Alerts Management",
    link: "/asset-management",
    page: 14,
  },
  // {
  //   icon: "map",
  //   text: "GPS Trackers",
  //   link: "/add-tracker",
  //   page: 16,
  // },
  {
    icon: "map",
    text: "Fleet Tracking",
    link: "/fleet-tracker",
    page: 29,
  },

  {
    icon: "coins",
    text: "Payments",
    page: 17,
    subMenu: [
      {
        icon: "coins",
        text: "Payment",
        link: "/Payment",
        page: 17,
      },
      {
        icon: "wallet-out",
        text: "Transactions",
        link: "/transactions",
        page: 19,
      },
      {
        icon: "wallet-saving",
        text: "Wallet Transactions",
        link: "/wallet-transactions",
        page: 59,
      },
    ],
  },
  // {
  //   icon: "property",
  //   text: "Beverage History",
  //   link: "/beverage-history",
  //   page: 18,
  // },
  {
    icon: "reports-alt",
    text: "Reports",
    page: 17,
    subMenu: [
      // {
      //   icon: "property-alt",
      //   text: "Analytics",
      //   link: "/analytics",
      //   page: 26,
      // },
      {
        icon: "reports",
        text: "Coach Report",
        link: "/coachdata-history",
        page: 56,
      },
      {
        icon: "ticket",
        text: "Purchase by Ticket Type",
        link: "/purchase-fare",
        page: 60,
      },
      {
        icon: "sign-dollar",
        text: "Revenue Report",
        link: "/RevenueReport",
        page: 61,
      },
      {
        icon: "calender-date",
        text: "Monthly Report",
        link: "/monthlyreport",
        page: 62,
      },
      {
        icon: "tranx",
        text: "Bank Deposits Summary",
        link: "/depositsummary",
        page: 63,
      },
      {
        icon: "users",
        text: "User Report",
        link: "/users",
        page: 25,
      },
      process.env.REACT_APP_PANEL_TEXT === "MDOT" && {
        icon: "user-circle",
        text: "In Range Users",
        link: "/inrange-users",
        page: 67,
      },
    ],
  },
  localStorage.getItem("email") === "oktoberfest@zed.digital" ?
  {
    icon: "user-list",
    text: "History",
    link: "/visitorhistory",
    page: 21,
  }:
  {
    icon: "user-list",
    text: "Rider History",
    link: "/visitorhistory",
    page: 21,
  },
  process.env.REACT_APP_PANEL_TEXT === "MDOT" && {
    icon: "pen2",
    text: "Feedback",
    link: "/feedback",
    page: 21,
  },

  {
    icon: "list-round",
    text: "Rider Analytics",
    link: "/riders-analytics",
    page: 37,
  },
  {
    icon: "property",
    text: "Beverage List",
    link: "/counterlist",
    page: 22,
  },

  {
    icon: "user-alt-fill",
    text: "Visitors VIP",
    link: "/visitorsvip",
    page: 24,
  },
  {
    icon: "update",
    text: "Unactivated Tickets",
    link: "/unactivated",
    page: 53,
  },
  process.env.REACT_APP_PANEL_TEXT === "ZIG" && {
    icon: "layers",
    text: "ZIG AI Analytics",
    link: "/ZIGAIAnalytics",
    page: 88,
  },
  (process.env.REACT_APP_PANEL_TEXT === "ZIG" && localStorage.getItem("email") === "oktoberfest@zed.digital") && {
    icon: "layers",
    text: "Beverages",
    link: "/Beverages",
    page: 89,
  },
  {
    icon: "property-alt",
    text: "Lima Analytics",
    link: "/lima-analytics",
    page: 41,
  },
  // {
  //   icon: "property-alt",
  //   text: "Test Page",
  //   link: "/Testpage",
  //   page: 36,
  // },
  // {
  //   icon: "property-alt",
  //   text: "Vehicles",
  //   link: "/vehicles",
  //   page: 36,
  // },
  // {
  //   icon: "property-alt",
  //   text: "Add Beacon",
  //   link: "/addnewbeacon",
  //   page: 36,
  // },
  // {
  //   icon: "property-alt",
  //   text: "New Beacon",
  //   link: "/newbeacon",
  //   page: 36,
  // },
  {
    icon: "property-alt",
    text: "System Map",
    link: "/system-map",
    page: 46,
  },
  {
    icon: "property-alt",
    text: "Add Validator",
    link: "/newvalidator",
    page: 44,
  },
  {
    icon: "hard-drive",
    text: "Add Validator TOF Device",
    link: "/addvalidator-v2",
    page: 66,
  },
  {
    icon: "mobile",
    text: "Add Device",
    link: "/newdevice",
    page: 43,
  },
  {
    icon: "inbox-out",
    text: "Firmware OTA",
    link: "/firmware-ota",
    page: 65,
  },
  {
    icon: "ticket-alt-fill",
    text: "Generate Ticket",
    link: "/generateticket",
    page: 38,
  },

  process.env.REACT_APP_PANEL_TEXT === "ZIG" && {
    icon: "property-alt",
    text: "Rewards",
    link: "/rewards",
    page: 45,
  },
  {
    icon: "property-alt",
    text: "Alerts",
    link: "/alertpage",
    page: 40,
  },
  {
    icon: "cc-alt2-fill",
    text: "Attendance",
    link: "/attendance",
    page: 34,
  },
  {
    icon: "cc-alt2-fill",
    text: "Run",
    link: "/run",
    page: 35,
  },
  {
    icon: "chat-fill",
    text: "Support",
    link: "/support",
    page: 20,
  },
  {
    icon: "emails",
    text: "Email Subscriptions",
    link: "/Crudform",
    page: 39,
  },
  {
    icon: "ticket-fill",
    text: "All Tickets",
    link: "/AllTickets",
    page: 47,
  },
  {
    icon: "users-fill",
    text: "All Users",
    link: "/AllUsers",
    page: 50,
  },
  {
    icon: "view-list-sq",
    text: "Toll Analytics",
    link: "/TollAnalytics",
    page: 48,
  },
  {
    icon: "view-list-sq",
    text: "User Log",
    link: "/userlogs",
    page: 77,
  },
  // {
  //   icon: "list-thumb-fill",
  //   text: "Form",
  //   link: "/Form",
  //   page: 20,
  // },
];

const filteredMenu = menu.map((menuItem) => {
  if (menuItem.subMenu) {
    return {
      ...menuItem,
      subMenu: menuItem.subMenu.filter(
        (subItem) => !(process.env.REACT_APP_PANEL_TEXT === "ZIG" && ["/coachdata-history"].includes(subItem.link))
      ),
    };
  }

  return menuItem;
});

export default filteredMenu;
