import React, { lazy, useEffect, useState, createContext } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { Switch, Route, withRouter } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";
import PrivateRoute from "./route/PrivateRoute";
import { getIpAddress } from "./utils/ipService";
import axios from "axios";
import Layout from "./layout/Index";
import Login from "./pages/auth/Login";

const Register = lazy(() => import("./pages/auth/Register"));
export const IpAddressContext = createContext(null);

const App = (props) => {
  const [ip, setIp] = useState("");
  datadogRum.init({
    applicationId: "92a7f8ef-8ee7-470b-8bc7-5a55dd628ab5",
    clientToken: "pub92cfae4c2dfbd64a9e9c788eba6e6af5",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "us5.datadoghq.com",
    service: "mdot-panel",
    env: "prod",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    startSessionReplayRecordingManually: true,
  });

  useEffect(() => {
    const fetchIpAndLog = async () => {
      try {
        const ip = await getIpAddress();
        setIp(ip);

        const email = localStorage.getItem("email") || "";
        const user_id = localStorage.getItem("clientid") || 0;
        const isLogged = !!localStorage.getItem("accessToken");

        const logBody = {
          email,
          clientid: user_id,
          ip_add: ip,
          isLogged,
          isNewLogin: false,
        };

        if (isLogged) {
          console.log("rum session started");
          datadogRum.startSessionReplayRecording();
        }

        await axios.post(`${process.env.REACT_APP_CONFIG_BASE_URL}panel/userLog`, logBody);
      } catch (error) {
        console.error("Failed to fetch IP and log user session:", error);
      }
    };

    fetchIpAndLog();
  }, []);

  return (
    <IpAddressContext.Provider value={ip}>
      <Switch>
        {/* Auth Pages  */}
        <Route exact path={`${process.env.PUBLIC_URL}/auth-register`} component={Register}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/auth-login`} component={Login}></Route>
        {/* Main Routes */}
        <PrivateRoute exact path="" component={Layout}></PrivateRoute>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </IpAddressContext.Provider>
  );
};

export default withRouter(App);
