import React, { useEffect, useRef, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { successAlert, failureAlert } from "../utils/Utils";
import SimpleBar from "simplebar-react";
import DataCard from "../components/partials/default/DataCard";
import { DefaultOrderChart, DefaultRevenueChart } from "../components/partials/charts/default/DefaultCharts";
import TrafficSources from "../components/partials/e-commerce/traffic-sources/FleetTrackingChart";
import { MapContainer, TileLayer, Marker, Polyline, useMap, Tooltip } from "react-leaflet";
import { icon } from "leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import redBus from "../assets/images/redBusNew.png";
import greenBus from "../assets/images/greenBus.png";
import yellowBus from "../assets/images/yellowBus.png";
import blackBus from "../assets/images/blackBus.png";

import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  BlockBetween,
  PaginationComponent,
  Col,
  Row,
  DataTablePagination,
} from "../components/Component";
import styled from "styled-components";

import {
  Button,
  Card,
  Spinner,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import DiagnoseTrackerModal from "./components/DiagnoseTrackerModal/DiagnoseTrackerModal";
// import AddTrackerModal from "../pages/components/AddTrackerModal/AddTrackerModal";
import { user_id } from "../redux/userSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backgroundImage from "../assets/images/fleet_tracking.png";
import TimeDifference from "../components/TimeDifference";
import axios from "axios";

import { selectSavedValue, selectSavedLabel } from "../redux/sessionClientSlice";
import { role } from "../redux/userSlice";
import DataTable from "react-data-table-component";
import { format } from "url";

const FleetTracker = () => {
  const clientValueId = useSelector(selectSavedValue);
  const clientValueLabel = useSelector(selectSavedLabel);
  const userrole = useSelector(role);

  const history = useHistory();
  const VEHICLE_TYPES = { 1: "Truck", 2: "Car", 3: "Maxi Cab", 4: "Bike", 5: "Bus", 10: "Tanker" };
  const DEVICE_MODE_BADGE = { PARKED: "warning", MOVING: "success", OFFLINE: "danger" };
  const userId = useSelector(user_id);
  const [clients, setClients] = useState([]);
  const initialTrackers = useRef([]);
  const [view, setView] = useState({
    edit: false,
    add: false,
    diagnose: false,
  });

  const username = localStorage.getItem("username");
  const [centerLatLng, setCenterLatLng] = useState([43.003751, -84.544988]);

  const [systemMapValues, setSystemMapValues] = useState([]);
  const [mapCenter, setMapCenter] = useState([0, 0]);

  const [viewOption, setViewOption] = useState("");
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [trackers, setTrackers] = useState([]);
  const [sm, updateSm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [diagnoseImei, setDiagnoseImei] = useState();
  const [itemPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const [filteredTrackers, setFilteredTrackers] = useState([]);
  let currentItems = filteredTrackers.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      diagnose: type === "diagnose" ? true : false,
    });
  };
  const onFilterChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    if (username === "ZED Demo") {
      const specificVehicles = initialTrackers.current.filter(
        (item) => item.vehicleNo === "B44" || item.vehicleNo === "B908"
      );

      if (e.nativeEvent.inputType === "deleteContentBackward" && searchText.length === 0) {
        setTrackers(specificVehicles);
      } else {
        const filteredData = specificVehicles.filter((item) =>
          item.vehicleNo.toLowerCase().includes(searchText.toLowerCase())
        );
        setTrackers(filteredData);
      }
    } else {
      if (e.nativeEvent.inputType === "deleteContentBackward" && searchText.length === 0) {
        setTrackers(initialTrackers.current);
      } else {
        const filteredData = initialTrackers.current.filter((item) =>
          item.vehicleNo.toLowerCase().includes(searchText.toLowerCase())
        );
        setTrackers(filteredData);
      }
    }
  };

  // const busIcon = icon({
  //   iconUrl: redBus,
  //   iconSize: [30, 30],
  // });

  const RecenterAutomatically = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng]);
    }, [lat, lng]);
    return null;
  };
  const onSubmitHandler = (data) => {
    axios
      .post("api/Device", { ...data, DeviceType: 1, OdometerValueNew: 0, Devicemode: "offline", client_id: userId })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          let newTrackers = [...trackers];
          newTrackers.push(res.data);
          console.log(newTrackers);
          setTrackers(newTrackers);
          setView({
            edit: false,
            add: false,
            diagnose: false,
          });
          successAlert("Tracker added successfully");
        } else {
          failureAlert("Error");
        }
      });
  };

  const onEditClick = (id) => {
    const selectedTracker = trackers.find((item) => item.id === id);
    setFormData({
      ...selectedTracker,
    });
  };
  const onEditSubmit = (data) => {
    console.log(data);
    axios
      .put("api/RegisterDevice", {
        imei: data.imei,
        client_id: data.client_id,
        vehicleNo: data.vehicleNo,
        OdometerValue: data.OdometerValue,
        vehicleType: data.vehicleType,
      })
      .then((res) => {
        if (res.status === 200) {
          const newTrackers = [...trackers];
          const editedIdx = newTrackers.findIndex((item) => item.id === data.id);
          newTrackers[editedIdx] = { ...data };
          setTrackers(newTrackers);
          setView({
            edit: false,
            add: false,
            diagnose: false,
          });
          successAlert("Tracker edited successfully");
        } else {
          failureAlert("Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onFormCancel = () => {
    setView({ edit: false, add: false, diagnose: false });
  };
  const redirectToWidget = (imei, deviceMode) => {
    const tracker = trackers.find((item) => item.imei === imei);
    console.log(tracker.vehicleType);
    history.push("/vehicle-info", {
      imei: imei,
      vehicleType: tracker.vehicleType,
      vehicleNo: tracker.vehicleNo,
      DeviceType: tracker.DeviceType,
      DeviceMode: deviceMode,
    });
  };

  useEffect(() => {
    console.log(viewOption);
    console.log(filteredTrackers.length);
    let defaultData = trackers;
    if (viewOption !== "" && viewOption !== "All") {
      defaultData = trackers.filter((item) => {
        return item?.Devicemode.toString().toLowerCase().includes(viewOption.toLowerCase());
      });
      setFilteredTrackers(defaultData);
    } else {
      setFilteredTrackers(trackers);
    }
    setCurrentPage(1);
  }, [viewOption, trackers]);

  useEffect(() => {
    if (username === "ZED Demo") {
      const filteredObject = initialTrackers.current.filter((item) => {
        return (
          (item.vehicleNo === "B44" || item.vehicleNo === "B908") &&
          item.vehicleNo.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });

      setTrackers([...filteredObject]);
    } else {
      if (onSearchText !== "") {
        const filteredObject = initialTrackers.current.filter((item) => {
          return item.vehicleNo.toLowerCase().includes(onSearchText.toLowerCase());
        });

        setTrackers([...filteredObject]);
      } else {
        setTrackers([...initialTrackers.current]);
      }
    }
    setCurrentPage(1);
  }, [onSearchText, username]);

  // const useremail = localStorage.getItem("useremail");

  // useEffect(() => {
  //   const getTrackers = async () => {
  //     // let response;
  //     // const response = await axios.get("https://gps-v2.zig-app.com/api/getdeviceDetails/98");

  //     //const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getdeviceDetails?id=` + userId);

  //     const response = await axios.get(`${process.env.REACT_APP_BUS_CONFIG_URL}gpsData?client_id=85`);

  //     // if(userId === 14) {
  //     //   response = await axios.get("https://gps-v2.zig-app.com/api/getdeviceDetails/905");
  //     // } else {
  //     //   response = await axios.get("https://gps-v2.zig-app.com/api/getdeviceDetails/98");
  //     // }

  //     return response.data;
  //   };
  //   setLoading(true);
  //   getTrackers()
  //     .then((res) => {
  //       setTrackers([...res]);
  //       initialTrackers.current = [...res];
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    console.log("Clientvalue label", clientValueLabel);
    if (clientValueLabel === "Clinton Area Transit") {
      setCenterLatLng([43.003751, -84.544988]);
    } else if (clientValueLabel === "LETS Public Transit") {
      setCenterLatLng([42.631883, -83.992815]);
    } else if (clientValueLabel === "Schoolcraft transit authority") {
      setCenterLatLng([45.966544, -86.237193]);
    } else if (clientValueLabel === "Southfield - Xpress Transportation") {
      setCenterLatLng([42.472346, -83.300743]);
    } else if (clientValueLabel === "Marquette County Transit") {
      setCenterLatLng([46.557487, -87.452332]);
    } else if (clientValueLabel === "Isabella County Transportation") {
      setCenterLatLng([43.5989, -84.805517]);
    } else if (clientValueLabel === "Delta Area Transit Authority") {
      setCenterLatLng([45.7795036, -87.0901098]);
    } else if (clientValueLabel === "St. Joseph County Transit") {
      setCenterLatLng([41.9521345, -85.6489116]);
    } else if (clientValueLabel === "Benzie transportation authority") {
      setCenterLatLng([44.6602864, -85.9589646]);
    }
  }, [clientValueLabel]);

  useEffect(() => {
    const getTrackers = async () => {
      try {
        // setLoading(true);
        let response;

        if (username === "zig" || username === "Viaplus" || username === "ZED Demo") {
          response = await axios.get(`https://gps-v2.zed-admin.com/api/getdeviceDetails/8`);
        } else {
          if (userrole === 3 && clientValueId) {
            response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getdeviceDetails?id=` + clientValueId);
          } else {
            response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getdeviceDetails?id=` + userId);
          }
        }

        // Filter vehicles only if username is "ZED Demo"
        const filteredVehicleData =
          username === "ZED Demo"
            ? response.data.filter((device) => device.vehicleNo === "B44" || device.vehicleNo === "B908")
            : response.data;

        setTrackers([...filteredVehicleData]);
        initialTrackers.current = [...filteredVehicleData]; // Update initialTrackers to store filtered data

        const mapValues = filteredVehicleData.map((device) => ({
          Devicemode: device.Devicemode,
          vehicleNo: device.vehicleNo,
          vehicleType: device.vehicleType,
          imei: device.imei,
          timestamp: device.details[0].timestamp,
          Latitude: parseFloat(device.details[0].Latitude),
          Longitude: parseFloat(device.details[0].Longitude),
        }));

        setSystemMapValues(mapValues);

        if (mapValues.length > 0) {
          const firstDevice = mapValues[0];
          setMapCenter([firstDevice.Latitude, firstDevice.Longitude]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getTrackers();

    // const intervalId = setInterval(() => {
    //   getTrackers();
    // }, 15000);

    // return () => clearInterval(intervalId);
  }, [clientValueId, username, userrole, userId]);

  // useEffect(() => {
  //   const getClients = async () => {
  //     const response = await axios.get("api/getUserDetails");
  //     return response.data;
  //   };
  //   getClients().then((res) => {
  //     setClients([...res]);
  //   });
  // }, []);
  const calculateDeviceModeBadge = (timestamp) => {
    const timestampDate = new Date(timestamp.replace(" ", "T") + "Z");
    const now = new Date();
    const diffInMilliseconds = now - timestampDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInDays = Math.floor(diffInSeconds / (24 * 3600));

    if (diffInSeconds < 180) {
      return "MOVING";
    } else if (diffInSeconds < 900) {
      return "IDLE";
    } else if (diffInDays < 21) {
      return "PARKED";
    } else if (diffInDays >= 21) {
      return "OFFLINE";
    }

    // Default to MOVING if none of the conditions match
    return "OFFLINE";
  };

  const calculateDeviceMode = (timestamp) => {
    const timestampDate = new Date(timestamp.replace(" ", "T") + "Z");
    const now = new Date();
    const diffInMilliseconds = now - timestampDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInDays = Math.floor(diffInSeconds / (24 * 3600));

    let iconUrl;
    if (diffInSeconds < 180) {
      iconUrl = greenBus;
    } else if (diffInSeconds < 900) {
      iconUrl = blackBus;
    } else if (diffInDays < 21) {
      iconUrl = yellowBus;
    } else if (diffInDays >= 21) {
      iconUrl = redBus;
    } else {
      iconUrl = redBus;
    }

    return icon({
      iconUrl,
      iconSize: [30, 30],
    });
  };
  const calculateDeviceModeLabel = (timestamp) => {
    const timestampDate = new Date(timestamp.replace(" ", "T") + "Z");
    const now = new Date();
    const diffInMilliseconds = now - timestampDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInDays = Math.floor(diffInSeconds / (24 * 3600));

    if (diffInSeconds < 180) {
      return (
        <Badge pill color="success">
          Moving
        </Badge>
      );
    } else if (diffInSeconds < 900) {
      return (
        <Badge pill color="dark">
          Idle
        </Badge>
      );
    } else if (diffInDays < 21) {
      return (
        <Badge pill color="warning">
          Parked
        </Badge>
      );
    } else if (diffInDays >= 21) {
      return (
        <Badge pill color="danger">
          Offline
        </Badge>
      );
    }

    return "Offline";
  };
  const sortedItems = [...currentItems].sort((a, b) => {
    // Convert timestamp strings to actual timestamps for accurate sorting
    const timestampA = new Date(a.details[0].timestamp.replace(" ", "T") + "Z").getTime();
    const timestampB = new Date(b.details[0].timestamp.replace(" ", "T") + "Z").getTime();
    return timestampB - timestampA;
  });
  // Inside the FleetTracker component
  useEffect(() => {
    // Sorting function for Last Updated timestamp in ascending order
    const sortByLastUpdated = (a, b) => {
      // Convert timestamp strings to actual timestamps for accurate sorting
      const timestampA = new Date(a.details[0].timestamp.replace(" ", "T") + "Z").getTime();
      const timestampB = new Date(b.details[0].timestamp.replace(" ", "T") + "Z").getTime();
      return timestampB - timestampA; // Sort in ascending order
    };

    // Sort the entire list of items based on last updated timestamp
    const sortedItems = [...trackers].sort(sortByLastUpdated);

    // Set the sorted items to state
    setFilteredTrackers(sortedItems);
  }, [trackers]); // Trigger sorting whenever trackers changes

  //datatable
  const [rowsPerPageS, setRowsPerPage] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  const columns = [
    {
      name: <div style={{ margin: "0 auto" }}>{"Coach No"}</div>,
      selector: (row) => row.vehicleNo,
      grow: 0.5,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Vehicle Type"}</div>,
      selector: (row) => row.routename,
      format: (row) => {
        return <div>{VEHICLE_TYPES[row.vehicleType]}</div>;
      },
      grow: 1,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"IMEI"}</div>,
      selector: (row) => row.imei,
      grow: 1,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"SIM No"}</div>,
      selector: (row) => row.simno,
      grow: 1.5,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Vehicle Mode"}</div>,
      selector: (row) => row.routename,
      format: (row) => {
        return (
          <div>
            <Badge color={DEVICE_MODE_BADGE[calculateDeviceModeBadge(row.details[0].timestamp)]}>
              {calculateDeviceModeBadge(row.details[0].timestamp)}
            </Badge>
          </div>
        );
      },
      grow: 1,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Last Updated"}</div>,
      selector: (row) => row.routename,
      format: (row) => {
        return (
          <div>
            <TimeDifference timestamp={row.details[0].timestamp} />
          </div>
        );
      },
      grow: 1,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"View More"}</div>,
      selector: (row) => row,
      cell: (row) => (
        <div style={{ textAlign: "center" }}>
          <a
            href="#edit"
            onClick={(ev) => {
              ev.preventDefault();
              redirectToWidget(row.imei, calculateDeviceModeBadge(row.details[0].timestamp));
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Icon name="eye" />
          </a>
        </div>
      ),
      grow: 0.4,
      center: true,
    },
  ].filter((column) => column !== null);

  return (
    <React.Fragment>
      <Head title="Add Tracker"></Head>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: "300px",
          paddingTop: "125px",
        }}
      >
        <BlockTitle page tag="h3">
          <Title>Fleet Tracking</Title>
        </BlockTitle>
      </div>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent></BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="gy-4">
            <Col sm="4">
              <div className="card-title">
                <h6 className="title">Fleet Status</h6>
              </div>
              <TrafficSources device={trackers} />
            </Col>
            <Col sm="8">
              <div className="card-title">
                <h6 className="title">System Map</h6>
              </div>
              <div style={{ width: "100%", height: "350px" }}>
                <div className="leaflet-container px-2 pb-2 pt-2" style={{ backgroundColor: "#fff" }}>
                  {isLoading ? (
                    <div className="text-center" style={{ marginTop: "100px" }}>
                      <Spinner />
                    </div>
                  ) : systemMapValues.length > 0 ? (
                    <MapContainer center={centerLatLng} zoom={9} style={{ height: "100%", width: "100%" }}>
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <RecenterAutomatically lat={centerLatLng[0]} lng={centerLatLng[1]} />
                      {systemMapValues.map((device, index) => (
                        <Marker
                          key={index}
                          position={[device.Latitude, device.Longitude]}
                          icon={calculateDeviceMode(device.timestamp)}
                          eventHandlers={{
                            click: (ev) => {
                              console.log("Clicked");
                              redirectToWidget(device.imei, calculateDeviceModeBadge(device.timestamp));
                            },
                          }}
                        >
                          <Tooltip direction="top" style={{ padding: "10px", fontSize: "14px" }}>
                            {
                              <div>
                                <h6 style={{ fontSize: "14px" }}>Coach No: {device.vehicleNo}</h6>
                                <label style={{ fontWeight: "bold" }}>
                                  Mode: {calculateDeviceModeLabel(device.timestamp)}
                                </label>
                              </div>
                            }
                          </Tooltip>
                        </Marker>
                      ))}
                    </MapContainer>
                  ) : (
                    <div className="text-center" style={{ marginTop: "100px", fontSize: "12px" }}>
                      Try again later
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row className="gy-4">
            <Col sm="2" lg="3">
              <div className="form-control-wrap">
                <div className="form-icon form-icon-right">
                  <Icon name="search"></Icon>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="default-04"
                  placeholder="Search by Coach No"
                  onChange={(e) => onFilterChange(e)}
                />
              </div>
            </Col>
          </Row>
        </Block>

        <Block>
          <div className="nk-ecwg nk-ecwg6" style={{ marginTop: "-20px" }}>
            <Block>
              {isLoading ? (
                <Card>
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <Spinner color="primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ) : (
                <DataTable
                  data={sortedItems}
                  columns={columns}
                  noDataComponent={<div className="pt-5 pb-5 text-center">No Trackers found</div>}
                  sortIcon={
                    <div>
                      <span>&darr;</span>
                      <span>&uarr;</span>
                    </div>
                  }
                  defaultSortField="timestamp"
                  pagination={false}
                  paginationTotalRows={filteredTrackers.length}
                  paginationPerPage={rowsPerPageS}
                />
              )}
            </Block>
          </div>

          <div className="card-inner">
            {trackers.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={filteredTrackers.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">{isLoading ? <Spinner color="primary" /> : "No trackers found"}</span>
              </div>
            )}
          </div>
        </Block>

        {/* Below is the Diagnose Modal */}
        <Modal isOpen={view.diagnose} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <DiagnoseTrackerModal imei={diagnoseImei} />
            </div>
          </ModalBody>
        </Modal>
        {/* Below is the Edit Modal*/}
        <Modal isOpen={view.edit} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            {/* <div className="p-2">
              <AddTrackerModal onSubmitHandler={onEditSubmit} isEdit={true} formData={formData} clients={clients} />
            </div> */}
          </ModalBody>
        </Modal>
        {/* Below is the add tracker modal */}
        <SimpleBar
          className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${
            view.add ? "content-active" : ""
          }`}
        >
          {/* <AddTrackerModal onSubmitHandler={onSubmitHandler} /> */}
        </SimpleBar>

        {view.add && <div className="toggle-overlay" onClick={toggle}></div>}
      </Content>
    </React.Fragment>
  );
};
const Title = styled.h3`
  font-size: 112px;
  font-weight: 900;
  padding-left: 32px;

  @media (max-width: 768px) {
    font-size: 50px;
    padding: 20px;
  }
`;

export default FleetTracker;
export const calculateDeviceModeBadge = (item) => {
  const timestampDate = new Date(item.details[0].timestamp.replace(" ", "T") + "Z");
  const now = new Date();
  const diffInMilliseconds = now - timestampDate;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInDays = Math.floor(diffInSeconds / (24 * 3600));

  if (diffInSeconds < 180) {
    return "MOVING";
  } else if (diffInSeconds < 900) {
    return "IDLE";
  } else if (diffInDays < 21) {
    return "PARKED";
  } else if (diffInDays >= 21) {
    return "OFFLINE";
  }

  // Default to MOVING if none of the conditions match
  return "OFFLINE";
};
