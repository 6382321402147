import React, { useState } from "react";

const SiteCreator = () => {
  const [siteName, setSiteName] = useState("");
  const [customDomain, setCustomDomain] = useState("");
  const [repoUrl, setRepoUrl] = useState("");

  const handleCreateSite = async () => {
    const NETLIFY_TOKEN = "0xCpQKHFiKrkIONRxzZzywEkFjGq32-6hkI5XRK1bu0";
    const headers = {
      Authorization: `Bearer ${NETLIFY_TOKEN}`,
      "Content-Type": "application/json",
    };

    const repoInfo = {
      provider: "bitbucket", // Change to the appropriate provider (e.g., github, gitlab, bitbucket)
      repo_url: repoUrl,
      repo_path: "zeddigital/ecolane-admin-panel",
      repo_branch: "master", // Specify the repository branch
    };

    const siteData = {
      name: siteName,
      custom_domain: customDomain,
      repo: repoInfo,
      build_settings: {
        cmd: "npm run build",
        dir: "build",
      },
    };

    try {
      const response = await fetch("https://api.netlify.com/api/v1/sites/", {
        method: "POST",
        headers,
        body: JSON.stringify(siteData),
      });

      if (response.status === 201) {
        const siteInfo = await response.json();
        alert(`Successfully created site: ${siteInfo.name}`);
        // Handle further actions or UI updates here
      } else {
        alert("Error creating site");
      }
    } catch (error) {
      console.error("Error creating site:", error);
      alert("An error occurred while creating the site");
    }
  };

  return (
    <div>
      <h2>Create a New Netlify Site</h2>
      <div>
        <label>
          Site Name:
          <input type="text" value={siteName} onChange={(e) => setSiteName(e.target.value)} />
        </label>
      </div>
      <div>
        <label>
          Custom Domain:
          <input type="text" value={customDomain} onChange={(e) => setCustomDomain(e.target.value)} />
        </label>
      </div>
      <div>
        <label>
          Repository URL:
          <input type="text" value={repoUrl} onChange={(e) => setRepoUrl(e.target.value)} />
        </label>
      </div>
      <button onClick={handleCreateSite}>Create Site</button>
    </div>
  );
};

export default SiteCreator;
