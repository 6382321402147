import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { Col, DataTablePagination } from "../../Component";

import DataTable from "react-data-table-component";
import { Spinner, Badge } from "reactstrap";
import Moment from "react-moment";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

const UserAccessMetrics = ({ dateRanges }) => {
  const [loginCounts, setLoginCounts] = useState([]);
  const [totLength, setTotLength] = useState(0);
  const [totSuccLength, setTotSuccLength] = useState(0);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedClientData, setSelectedClientData] = useState([]);
  const [androidCnt, setAndroidCnt] = useState(0);
  const [iOSCnt, setIOSCnt] = useState(0);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };


  useEffect(() => {
    const fetchLoginCounts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_CONFIG_BASE_URL}panel/user/loginmetrics?startDate=${formatDate(
          dateRanges.start
        )}&endDate=${formatDate(dateRanges.end)}`);

        const responseData = await response.json();
        const sortedData = responseData.data.sort((a, b) => new Date(b.createddate) - new Date(a.createddate));
        const validUsersCount = sortedData.filter((item) => item.userid !== null && item.username !== "").length;
        const androidCount = sortedData.filter((item) => item.userid !== null && item.username !== "" && item.application === 2).length;
        const iOSCount = sortedData.filter((item) => item.userid !== null && item.username !== "" && item.application === 3).length;

        setAndroidCnt(androidCount);
        setIOSCnt(iOSCount);
        setLoginCounts(sortedData);
        setTotLength(sortedData.length);
        setTotSuccLength(validUsersCount);
        setSelectedClientData(responseData.data);

        // setLoading(false);
      } catch (error) {
        //Reset chart data
        //setLoading(false);
        setSelectedClientData([]);
        setLoginCounts([]);

        console.error("Error fetching login counts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLoginCounts();
  }, [dateRanges.start, dateRanges.end]);

  const panelText = process.env.REACT_APP_PANEL_TEXT;

  const [rowsPerPageS, setRowsPerPage] = useState(7);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  return (
    <React.Fragment>
      <Col xxl="12">
        <Card className="card-full">
          <div className="card-inner">
            <div className="d-flex flex-row align-items-start justify-content-between">
              <div className="card-title">
                <h6 className="title">User Access Metrics</h6>
                <ul className="list-inline">
                  <li>
                    <span>
                      Total Login Attempts:{" "}
                      <span style={{ color: "#1270F2", fontWeight: "bold" }}>
                        {totLength}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Successful Logins: <span style={{ color: "teal", fontWeight: "bold" }}>{totSuccLength}</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Android Users: <span style={{ fontWeight: "bold" }} className="text-danger">{androidCnt}</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      iOS Users: <span style={{ fontWeight: "bold" }} className="text-danger">{iOSCnt}</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-tools"></div>
            </div>
            {loading ? (
              <Card>
                <div className="nk-ecwg nk-ecwg6">
                  <div className="card-inner">
                    <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                      <div>
                        <Spinner color="primary" />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            ) : (
              // Display spinner while loading
              <DataTable
                data={selectedClientData}
                columns={columns}
                // customStyles={customStyles}
                noDataComponent={<div className="pt-5 pb-5 text-center">No Sessions Found</div>}
                sortIcon={
                  <div>
                    <span>&darr;</span>
                    <span>&uarr;</span>
                  </div>
                }
                defaultSortField="createddate"
                pagination
                // paginationServer
                // paginationComponentOptions={{
                //   rowsPerPageText: "Rows per page:",
                //   rangeSeparatorText: "of",
                //   selectAllRowsItem: true,
                //   selectAllRowsItemText: "All",
                // }}
                paginationTotalRows={loginCounts.length}
                paginationPerPage={rowsPerPageS}
                //paginationRowsPerPageOptions={[7, 14, 21, 28]}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
                  <DataTablePagination
                    customItemPerPage={rowsPerPageS}
                    itemPerPage={rowsPerPage}
                    totalItems={rowCount}
                    paginate={onChangePage}
                    currentPage={currentPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                )}
              ></DataTable>
            )}
          </div>
        </Card>{" "}
      </Col>
    </React.Fragment>
  );
};

const columns = [
  {
    name: "User Id",
    selector: (row) => (row.userid === 0 ? "-" : row.userid !== undefined && row.userid !== null ? row.userid : "-"),
    sortable: true,
    sortFunction: (a, b) => {
      const aValue = a.userid === 0 ? null : a.userid;
      const bValue = b.userid === 0 ? null : b.userid;

      if (aValue === null && bValue === null) return 0;
      if (aValue === null) return 1;
      if (bValue === null) return -1;

      return aValue - bValue;
    },
    grow: 1,
  },
  {
    name: "OS",
    selector: (row) => (row.application === 3 ? "iOS" : "Android"),
    sortable: true,
    grow: 1,
  },
  {
    name: "Brand",
    selector: (row) => row.brand,
    sortable: true,
    grow: 1.5,
  },
  {
    name: "Model",
    selector: (row) => row.model,
    sortable: true,
    grow: 1.5,
  },
  {
    name: "Date",
    selector: (row) => new Date(row.createddate),
    sortable: true,
    sortFunction: (a, b) => new Date(a.createddate) - new Date(b.createddate),
    cell: (row) =>
      new Date(row.createddate).getFullYear() >= 2001 ? (
        <Moment format="MMMM Do YYYY, h:mm a">{row.createddate}</Moment>
      ) : (
        "-"
      ),
    grow: 2,
  },
  {
    name: "Successful Login",
    selector: (row) => (
      row.userid && row.username ? "Yes" : "No"),
    cell: (row) => {
      return (
        <div>
          {row.userid && row.username ? (
            <Badge className="badge-dot" color="success">
              Yes
            </Badge>
          ) : (
            <Badge className="badge-dot" color="danger">
              No
            </Badge>
          )}
        </div>
      );
    },
    sortable: true,
    sortFunction: (a, b) => {
      const aValue = a.userid && a.username ? 1 : 0;
      const bValue = b.userid && b.username ? 1 : 0;
      return aValue - bValue;
    },
    grow: 1.5,
  },
].filter((column) => column !== null);

export default UserAccessMetrics;
