import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import PlaybackMapTeltonika from "./PlaybackMapTeltonika";
import { BlockBetween, BlockHead, BlockHeadContent, Icon, PreviewCard, UserAvatar } from "../../../../components/Component";
import { Button, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const ParentComponent = ({ imei }) => {
    const [updatedDate, setUpdatedDate] = useState(new Date());
    const [durationSpeed, setDurationSpeed] = useState(500);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isPaused, setIsPaused] = useState(false);
    const [resetIcon, setResetIcon] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        //Reset speed when date changes
        setDurationSpeed(500);
        setIsPaused(false);
        setReload(false);
    }, [updatedDate, resetIcon])
    useEffect(() => {
        setResetIcon(false);
    }, [updatedDate])
    const lineStyle = {
        width: '1px',
        height: '38px',
        backgroundColor: '#EBEEF2',
        margin: '0 10px',
        display: 'inline-block',
    };

    return (
        <div style={{ position: 'relative', width: '100%' }} className="shadow">
            <div style={{ position: "relative", zIndex: 1000 }}>
                <PreviewCard>
                    <div className="d-flex justify-content-between align-items-center" style={{ height: "18px" }}>
                        <div className="form-control-wrap " style={{ width: '120px' }}>
                            <DatePicker selected={updatedDate} onChange={setUpdatedDate} className="form-control date-picker border border-primary" />
                        </div>
                        <div className="d-flex justify-content-center">
                            {!isEmpty &&
                                <>
                                    <div>
                                        {
                                            !resetIcon ?
                                                <Button className="btn-round btn-icon" color="secondary" size="md" onClick={() => setIsPaused(!isPaused)}>
                                                    <Icon name={isPaused ? "play" : "pause"} />
                                                </Button> :
                                                <Button className="btn-round btn-icon" color="secondary" size="md" onClick={() => { setResetIcon(false); setReload(true) }}>
                                                    <Icon name={"reload"} />
                                                </Button>
                                        }
                                    </div>
                                    <div style={lineStyle}></div>
                                    <div onClick={() => setDurationSpeed(500)} style={{ cursor: 'pointer', height: "5px" }}>
                                        <UserAvatar theme={durationSpeed === 500 ? "azure" : "azure-dim"} text="1x" className={"sm"} style={{ cursor: 'pointer' }}></UserAvatar>
                                    </div>
                                    <div onClick={() => setDurationSpeed(50)} className="ml-1" style={{ cursor: 'pointer', height: "5px" }}>
                                        <UserAvatar theme={durationSpeed === 50 ? "azure" : "azure-dim"} text="2x" className={"sm"}></UserAvatar>
                                    </div>
                                    <div onClick={() => setDurationSpeed(5)} className="ml-1" style={{ cursor: 'pointer', height: "5px" }}>
                                        <UserAvatar theme={durationSpeed === 5 ? "azure" : "azure-dim"} text="4x" className={"sm"}></UserAvatar>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </PreviewCard>
            </div>
            <div style={{ width: "100%", height: "345px", position: "relative", zIndex: 900 }}>
                <PlaybackMapTeltonika key={reload ? `${updatedDate.toISOString()}-reload` : `${updatedDate.toISOString()}`} date={updatedDate} imei={imei} durationSpeed={durationSpeed} setIsEmpty={setIsEmpty} isPaused={isPaused} resetIcon={resetIcon} setResetIcon={setResetIcon} />
            </div>
        </div>
    );
};

export default ParentComponent;
