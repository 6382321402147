// Speedometer.js
import React from 'react';
import GaugeChart from 'react-gauge-chart';

const Speedometer = ({ value }) => {
    return (
        <div>
            <GaugeChart
                id="speedometer-gauge"
                nrOfLevels={20}
                colors={["#00FF00", "#FFDD00", "#FF0000"]}
                arcWidth={0.3}
                textColor="rgba(0,0,0,0)"
                percent={value / 200}
                needleColor="#000000"
                hideText
                style={{ width: '140px' }}
            />
            <div style={{ textAlign: 'center', marginTop: '-10px' }}>
                <span style={{ fontSize: '16px' }}>{value} mph</span>
            </div>
        </div>
    );
};

export default Speedometer;
