import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  const logo = process.env.REACT_APP_SIGN_IN;
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img className="logo-dark logo-img" src={logo} alt="logo" />
    </Link>
  );
};

export default Logo;