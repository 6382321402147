import React, { useState } from "react";
import Head from "../layout/head/Head";
import { Card, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Row, Col } from "reactstrap";
import Content from "../layout/content/Content";
import { toast } from "react-toastify";
import axios from "axios";

import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../components/Component";
import { useForm } from "react-hook-form";
import { Steps, Step } from "react-step-builder";

const UserSettings = ({ sm, updateSm }) => {
  const [modalForm, setModalForm] = useState(false);
  const [modalSettingsForm, setModalSettingsForm] = useState(false);
  const [passStateOld, setPassStateOld] = useState(false);
  const [passStateNew, setPassStateNew] = useState(false);
  // const [pass, setPass] = useState("");
  const [passOld, setPassOld] = useState("");
  const [passNew, setPassNew] = useState("");

  const toggleForm = () => setModalForm(!modalForm);

  const toggleSettingsForm = () => setModalSettingsForm(!modalSettingsForm);
  const { errors, register, handleSubmit } = useForm();

  const passwordSuccessAlert = () => {
    toast.success("Password Updated Successfully!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };
  const passwordFailureAlert = () => {
    toast.error("Something went wrong! Please Try Again", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };

  const resetPassword = async () => {
    if(!passOld || !passNew) {
      return;
    }
    try {
      const access_token = localStorage.getItem("accessToken");
      const headers = {
        'Authorization': 'Bearer '+ access_token,
        'Content-Type': 'application/json'
      };
      const response = await resetPasswordAPICall(headers);

      // const response = await axios.post(`${process.env.REACT_APP_CONFIG_BASE_URL}client/resetPassword`, {
      //   "oldpassword": passOld,
      //   "newpassword": passNew
      // }, { headers });

      if (response.status === 200) {
        console.log('Password updated successfully!');
        toggleForm();
        passwordSuccessAlert();
        setPassOld("");
        setPassNew("");
        console.log(response.data);
        // localStorage.setItem("accessToken", response.data.access_token);
        // localStorage.setItem("refreshToken", response.data.refresh_token);
        localStorage.clear();
        window.location.reload();
      } else {
        console.log(response.data.message);
        passwordFailureAlert();
        //Expired access token
        
      }
    } catch (error) {
      console.error(error);
      passwordFailureAlert();
    } 
  };

  const resetPasswordAPICall = async (headers) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_CONFIG_BASE_URL}client/resetPassword`, {
        "oldpassword": passOld,
        "newpassword": passNew
      }, { headers });
      return response;
    } catch (error) {
      console.log(error);
      passwordFailureAlert();
    }
  };

  const Header = (props) => {
    return (
      <div className="steps clearfix">
        <ul>
          <li className={props.current >= 1 ? "first done" : "first"}>
            <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
              <span className="number">01</span> <h5>Authentication</h5>
            </a>
          </li>
          <li className={props.current >= 2 ? "done" : ""}>
            <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
              <span className="number">02</span> <h5>Client Config</h5>
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const passwordform = (props) => {
    const [formData, setFormData] = useState({
      name: "",
      password: "",
    });

    const onInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const { errors, handleSubmit, register } = useForm();

    const submitForm = (data) => {
      if (data.name === "test" && data.password === "1234") {
        props.next();
      } else {
        alert("Invalid Credentials");
      }
    };
    return (
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col md="6">
            <FormGroup>
              <label className="form-label" htmlFor="first-name">
                Name
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  name="name"
                  ref={register({ required: true })}
                  onChange={(e) => onInputChange(e)}
                  defaultValue={formData.name}
                />
                {errors.name && <span className="invalid">This field is required</span>}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row className="gy-4">
          <Col md="6">
            <FormGroup>
              <label className="form-label" htmlFor="first-name">
                Password
              </label>
              <div className="form-control-wrap">
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  name="password"
                  ref={register({ required: true })}
                  onChange={(e) => onInputChange(e)}
                  defaultValue={formData.password}
                />
                {errors.name && <span className="invalid">This field is required</span>}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
          </ul>
        </div>
      </form>
    );
  };

  const SettingsForm = (props) => {
    const [formData, setFormData] = useState({
      id: "",
      lines_url: "",
      gps_url: "",
      gps_token: "",
    });

    const onInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const { errors, handleSubmit, register } = useForm();

    const submitForm = (data) => {
      props.next();
    };
    return (
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col md="6">
            <FormGroup>
              <label className="form-label" htmlFor="first-name">
                id
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="id"
                  className="form-control"
                  name="id"
                  ref={register({ required: true })}
                  onChange={(e) => onInputChange(e)}
                  defaultValue={formData.id}
                />
                {errors.id && <span className="invalid">This field is required</span>}
              </div>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label" htmlFor="first-name">
                Line Url
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="lines_url"
                  className="form-control"
                  name="lines_url"
                  ref={register({ required: true })}
                  onChange={(e) => onInputChange(e)}
                  defaultValue={formData.lines_url}
                />
                {errors.lines_url && <span className="invalid">This field is required</span>}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row className="gy-4">
          <Col md="6">
            <FormGroup>
              <label className="form-label" htmlFor="first-name">
                Password
              </label>
              <div className="form-control-wrap">
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  name="password"
                  ref={register({ required: true })}
                  onChange={(e) => onInputChange(e)}
                  defaultValue={formData.password}
                />
                {errors.name && <span className="invalid">This field is required</span>}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
          </ul>
        </div>
      </form>
    );
  };
  const config = {
    before: Header,
  };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Profile Settings</BlockTitle>
              <BlockDes>
                <p>These settings will help you to keep your account secure.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Change Password</h6>
                    <p>Set a unique password to protect your account.</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Button onClick={toggleForm} color="primary">
                          Change Password
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Account Settings</h6>
                    <p>The following settings is managed by Zed Digital</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Button onClick={toggleSettingsForm} color="primary">
                          View / Edit Settings
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Block>
        <Modal isOpen={modalForm} toggle={toggleForm}>
          <ModalHeader
            toggle={toggleForm}
            close={
              <button className="close" onClick={toggleForm}>
                <Icon name="cross" />
              </button>
            }
          >
            Customer Info
          </ModalHeader>
          <ModalBody>
            <form>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Old Password
                  </label>
                  {/* <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                    Forgot Code?
                  </Link> */}
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassStateOld(!passStateOld);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passStateOld ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passStateOld ? "text" : "password"}
                    id="password"
                    name="passcode"
                    value={passOld}
                    onChange={(e) => setPassOld(e.target.value)}
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your passcode"
                    className={`form-control-lg form-control ${passStateOld ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    New Password
                  </label>
                  {/* <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                    Forgot Code?
                  </Link> */}
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassStateNew(!passStateNew);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passStateNew ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passStateNew ? "text" : "password"}
                    id="password"
                    name="passcode"
                    value={passNew}
                    onChange={(e) => setPassNew(e.target.value)}
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your passcode"
                    className={`form-control-lg form-control ${passStateNew ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(), resetPassword() }} size="lg">
                  Save Credentials
                </Button>
              </FormGroup>
            </form>
          </ModalBody>
        </Modal>

        {/* settings form             */}
        <Modal size="lg" isOpen={modalSettingsForm} toggle={toggleSettingsForm}>
          <ModalHeader
            toggle={toggleSettingsForm}
            close={
              <button className="close" onClick={toggleSettingsForm}>
                <Icon name="cross" />
              </button>
            }
          >
            Settings
          </ModalHeader>
          <ModalBody>
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <Steps config={config}>
                <Step component={passwordform}></Step>
                <Step component={SettingsForm}></Step>
              </Steps>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default UserSettings;
