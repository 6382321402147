import React, { useState, useEffect, useRef } from "react";
import "./total.css";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { user_id, username } from "../redux/userSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import DatePicker from "react-datepicker";
import {
    Spinner,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Card,
    Modal,
    ModalBody,
    Badge,
    FormGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    BlockBetween,
    Button,
    DataTablePagination,
    RSelect,
} from "../components/Component";
import DataTable from "react-data-table-component";
import moment from "moment";
import { role } from "../redux/userSlice";
import CommentWithReadMore from "../components/table/ReadMoreComp"; ``

//Export packages
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from "react-moment";
import { ResponsivePie } from "@nivo/pie";

const ExpandableRowDataComponent = ({ data }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    return (
        <React.Fragment>
            <Button color="" onClick={toggleModal}>
                <Icon style={{ color: "#007bff" }} name="eye"></Icon>
            </Button>
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                style={{ zIndex: 1000 }}
                className="modal-dialog-centered"
                size="xl"
            >
                <ModalBody>
                    <a href="#cancel" className="close">
                        {" "}
                        <Icon name="cross-sm" onClick={toggleModal}></Icon>
                    </a>
                    <div className="p-2 mt-3 d-flex align-items-start justify-content-between">
                        <div className="d-flex align-items-start justify-content-start">
                            <h5 className="title">More Details</h5>
                            <span className="ml-2" style={{ fontSize: "14px" }}>
                                (<Moment format="MMMM Do YYYY hh:mm a">{data.timestamp}</Moment>)
                            </span>
                        </div>
                        <div>
                            <h6 className="title">{data.userName}</h6>
                        </div>
                    </div>
                    <div className=" mb-4" style={{ border: "1px solid #dee2e6" }}></div>

                    <div className="px-4">
                        <Row className="justify-content-between">
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-danger" style={{ height: "250px" }}>
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <div className="card-title d-flex align-items-start ">
                                                <h6 className="title" style={{ textDecoration: "underline" }}>User Details</h6>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                User ID
                                                            </h6>
                                                        </div>
                                                        <span className="text-danger" style={{ fontSize: "15px" }}>{data.userId ? data.userId : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                User Name
                                                            </h6>
                                                        </div>
                                                        <span className="text-danger" style={{ fontSize: "15px" }}>{data.userName ? data.userName : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                User City
                                                            </h6>
                                                        </div>
                                                        <span className="text-danger" style={{ fontSize: "15px" }}>{data.userCity ? data.userCity : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                User Country
                                                            </h6>
                                                        </div>
                                                        <span className="text-danger" style={{ fontSize: "15px" }}>{data.userCountry ? data.userCountry : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-danger" style={{ height: "250px" }}>
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <div className="card-title d-flex align-items-start ">
                                                <h6 className="title" style={{ textDecoration: "underline" }}>App Details</h6>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                App Version
                                                            </h6>
                                                        </div>
                                                        <span className="text-danger" style={{ fontSize: "15px" }}>{data.appVersion ? data.appVersion : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                App Name
                                                            </h6>
                                                        </div>
                                                        <span className="text-danger" style={{ fontSize: "15px" }}>{data.appName ? data.appName : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-between mt-2">
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-success" style={{ height: "350px" }}>
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <div className="card-title d-flex align-items-start ">
                                                <h6 className="title" style={{ textDecoration: "underline" }}>Mobile Details</h6>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Mobile Type
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.mobileType ? data.mobileType : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Mobile Model
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.mobileModel ? data.mobileModel : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                OS
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.os ? data.os : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Display Language
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.displayLanguage ? data.displayLanguage : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Display Country
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.displayCountry ? data.displayCountry : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-success" style={{ height: "350px" }}>
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <div className="card-title d-flex align-items-start ">
                                                <h6 className="title" style={{ textDecoration: "underline" }}>Trip Details</h6>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Date
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.timestamp ? <Moment format="MMMM Do YYYY">{data.timestamp}</Moment> : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Status
                                                            </h6>
                                                        </div>
                                                        <span className={data.status === "Pass" ? "text-success" : "text-danger"} style={{ fontSize: "15px" }}>{data.status ? data.status : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Response Time
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.overallResponseTime ? (data.overallResponseTime === "N/A" ? "N/A" : data.overallResponseTime + " sec") : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Trip Plan Type
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.tripPlanType ? data.tripPlanType : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Suggestions
                                                            </h6>
                                                        </div>
                                                        <span className="text-success" style={{ fontSize: "15px" }}>{data.suggestions ? data.suggestions : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Trip Options
                                                            </h6>
                                                        </div>
                                                        <span style={{ fontSize: "15px" }}>{data.tripOptions === "true" ? (<Icon className={"text-success"} name="check-c" />) : (data.tripOptions === "false" ? (<Icon className={"text-danger"} name="cross-c" />) : "N/A")}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-between mt-2">
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-primary">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <h6 className="title " style={{ textDecoration: "underline", margin: "0 auto" }}>From Location</h6>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item center">
                                                        <span className="text-primary" style={{ fontSize: "15px" }}>{data.fromLocation ? data.fromLocation : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-primary">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <h6 className="title " style={{ textDecoration: "underline", margin: "0 auto" }}>To Location</h6>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item center">
                                                        <span className="text-primary" style={{ fontSize: "15px" }}>{data.toLocation ? data.toLocation : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-between mt-2">
                            <Col lg={12} xl={12} sm={12}>
                                <Card className="p-1 border border-primary">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <h6 className="title " style={{ textDecoration: "underline", margin: "0 auto" }}>User Query</h6>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item center">
                                                        <span className="text-primary" style={{ fontSize: "15px" }}>{data.userQuery ? data.userQuery : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-between mt-2">
                            <Col lg={12} xl={12} sm={12}>
                                <Card className="p-1 border border-primary">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <h6 className="title " style={{ textDecoration: "underline", margin: "0 auto" }}>Command Text</h6>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item center">
                                                        <span className="text-primary" style={{ fontSize: "15px" }}>{data.commandText ? data.commandText : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className={"mt-2"}>
                            <Col lg="12" sm="12">
                                <Card className="p-2 border border-secondary">
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <div className="card-title-group mb-2">
                                            <h6 className="title " style={{ textDecoration: "underline", margin: "0 auto" }}>Query</h6>
                                        </div>
                                        <div className="mt-2">
                                            {data.userVoiceInputUrl && data.userVoiceInputUrl !== "N/A" && data.status !== "Fail" ? (
                                                <audio controls>
                                                    <source src={data.userVoiceInputUrl ? data.userVoiceInputUrl : ""} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            ) : (
                                                <Badge>No voice found</Badge>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className={"mt-2"}>
                            <Col lg="12" sm="12">
                                <Card className="p-2 border border-secondary">
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <div className="card-title-group mb-2">
                                            <h6 className="title " style={{ textDecoration: "underline", margin: "0 auto" }}>Command</h6>
                                        </div>
                                        <div className="mt-2">
                                            {data.commandUrl && data.commandUrl !== "N/A" && data.status !== "Fail" ? (
                                                <audio controls>
                                                    <source src={data.commandUrl ? data.commandUrl : ""} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            ) : (
                                                <Badge>No voice found</Badge>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-between mt-2">
                            <Col lg={12} xl={12} sm={12}>
                                <Card className="p-1 border border-danger">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <h6 className="title " style={{ textDecoration: "underline", margin: "0 auto" }}>Failure Message</h6>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item center">
                                                        <span className="text-danger" style={{ fontSize: "15px" }}>{data.failureMessage ? data.failureMessage : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-warning">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <div className="card-title">
                                                <h6 className="title">API Status</h6>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Recorder Status</span>
                                                            <h6 style={{ fontSize: "16px" }}>{data.recorderStatus ? data.recorderStatus : "-"}</h6>
                                                        </div>
                                                        <Icon name="stop-circle" className="bg-primary-dim"></Icon>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Whisper API Status</span>
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                {data.whisperApiStatus ? data.whisperApiStatus : "-"}
                                                            </h6>
                                                        </div>
                                                        <Icon name="chat-circle" className="bg-purple-dim"></Icon>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Open AI API Status</span>
                                                            <h6 style={{ fontSize: "16px" }}>{data.openAiApiStatus ? data.openAiApiStatus : "-"}</h6>
                                                        </div>
                                                        <Icon name="target" className="bg-info-dim"></Icon>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Places API Status</span>
                                                            <h6 style={{ fontSize: "16px" }}>{data.placesApiStatus ? data.placesApiStatus : "-"}</h6>
                                                        </div>
                                                        <Icon name="location" className="bg-danger-dim"></Icon>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Upload API Status</span>
                                                            <h6 style={{ fontSize: "16px" }}>{data.uploadApiStatus ? data.uploadApiStatus : "-"}</h6>
                                                        </div>
                                                        <Icon name="upload" className="bg-warning-dim"></Icon>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-warning">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <div className="card-title d-flex align-items-start ">
                                                <h6 className="title">Response Time</h6>
                                                <span className="ml-1">({data.overallResponseTime ? data.overallResponseTime : "-"})</span>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Recorder Time</span>
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                {data.voiceRecordTime
                                                                    ? data.voiceRecordTime !== "N/A"
                                                                        ? data.voiceRecordTime + " sec"
                                                                        : data.voiceRecordTime
                                                                    : "-"}
                                                            </h6>
                                                        </div>
                                                        <Icon name="stop-circle" className="bg-primary-dim"></Icon>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Whisper API Time</span>
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                {data.whisperApiTime
                                                                    ? data.whisperApiTime !== "N/A"
                                                                        ? data.whisperApiTime + " sec"
                                                                        : data.whisperApiTime
                                                                    : "-"}
                                                            </h6>
                                                        </div>
                                                        <Icon name="chat-circle" className="bg-purple-dim"></Icon>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Open AI API Time</span>
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                {data.openAiApiTime
                                                                    ? data.openAiApiTime !== "N/A"
                                                                        ? data.openAiApiTime + " sec"
                                                                        : data.openAiApiTime
                                                                    : "-"}
                                                            </h6>
                                                        </div>
                                                        <Icon name="target" className="bg-info-dim"></Icon>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Places API Time</span>
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                {data.placesApiTime
                                                                    ? data.placesApiTime !== "N/A"
                                                                        ? data.placesApiTime + " sec"
                                                                        : data.placesApiTime
                                                                    : "-"}
                                                            </h6>
                                                        </div>
                                                        <Icon name="location" className="bg-danger-dim"></Icon>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <span style={{ fontSize: "14px" }}>Upload API Time</span>
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                {data.uploadApiTime
                                                                    ? data.uploadApiTime !== "N/A"
                                                                        ? data.uploadApiTime + " sec"
                                                                        : data.uploadApiTime
                                                                    : "-"}
                                                            </h6>
                                                        </div>
                                                        <Icon name="upload" className="bg-warning-dim"></Icon>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-between mt-2">
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-info">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <div className="card-title d-flex align-items-start ">
                                                <h6 className="title" style={{ textDecoration: "underline" }}>Nearby Places Data (Status)</h6>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Bottom Recorder Status
                                                            </h6>
                                                        </div>
                                                        <span className="text-info" style={{ fontSize: "15px" }}>{data.bottomRecorderStatus ? data.bottomRecorderStatus : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Bottom Whisper Status
                                                            </h6>
                                                        </div>
                                                        <span className="text-info" style={{ fontSize: "15px" }}>{data.bottomWhisperStatus ? data.bottomWhisperStatus : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Bottom Upload Status
                                                            </h6>
                                                        </div>
                                                        <span className="text-info" style={{ fontSize: "15px" }}>{data.bottomUploadStatus ? data.bottomUploadStatus : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={6} xl={6} sm={12}>
                                <Card className="p-1 border border-info">
                                    <div className="card-inner">
                                        <div className="card-title-group mb-2">
                                            <div className="card-title d-flex align-items-start ">
                                                <h6 className="title" style={{ textDecoration: "underline" }}>Nearby Places Data (Response Time)</h6>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col lg={12} xl={12}>
                                                <ul className="nk-store-statistics">
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Bottom Recorder Time
                                                            </h6>
                                                        </div>
                                                        <span className="text-info" style={{ fontSize: "15px" }}>{data.bottomVoiceRecordTime ? data.bottomVoiceRecordTime : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Bottom Whisper Time
                                                            </h6>
                                                        </div>
                                                        <span className="text-info" style={{ fontSize: "15px" }}>{data.bottomWhisperResponseTime ? data.bottomWhisperResponseTime : "-"}</span>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <h6 style={{ fontSize: "16px" }}>
                                                                Bottom Upload Time
                                                            </h6>
                                                        </div>
                                                        <span className="text-info" style={{ fontSize: "15px" }}>{data.bottomUploadResponseTime ? data.bottomUploadResponseTime : "-"}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

const ZIGAIAnalytics = ({ className, selectableRows }) => {
    const [data, setData] = useState([]);
    const [mobileTypeChart, setMobileTypeChart] = useState([]);
    const [statusChart, setStatusChart] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [totalCnt, setTotalCnt] = useState(0);
    const [AIAssistCnt, setAIAssistCnt] = useState(0);
    const [guideAssistCnt, setGuideAssistCnt] = useState(0);
    const [tripSuggestCnt, setTripSuggestCnt] = useState(0);
    const [othersCnt, setOthersCnt] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [selectedCity, setSelectedCity] = useState("All");
    const [cities, setCities] = useState([]);
    const [appVersionsAndroid, setAppVersionsAndroid] = useState([]);
    const [appVersionsiOS, setAppVersionsiOS] = useState([]);
    const [appVersionsWeb, setAppVersionsWeb] = useState([]);
    const [appVersions, setAppVersions] = useState([]);
    //More filtration
    const [androidChecked, setAndroidChecked] = useState(false);
    const [iosChecked, setIosChecked] = useState(false);
    const [webChecked, setWebChecked] = useState(false);

    const [selectedAssisType, setSelectedAssisType] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);

    const [dropdownFilter, setDropdownFilter] = useState(false);

    const assisTypes = [{ value: "ai assistant", label: "AI Assistant" },
    { value: "guide assistant", label: "Guide Assistant" },
    { value: "trip suggestions", label: "Trip Suggestions" },
    ];
    const cityRef = useRef(null);
    const initialRef = useRef(true);
    const hasSetAppVersions = useRef(true);

    const logoUrl = process.env.REACT_APP_SIGN_IN;
    const clientValueLabel = localStorage.getItem("label");
    const userrole = useSelector(role);

    const [expandData, setExpandData] = useState(false);

    //Filtration
    const filteredItems =
        displayData &&
        displayData.filter((item) => {
            const formattedCreatDate =
                item.timestamp !== null && item.timestamp !== "" ? moment(item.timestamp).format("MMMM Do YYYY, h:mm a") : "";

            return (
                (item.userId !== null &&
                    item.userId !== "" &&
                    item.userId.toString().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))) ||
                (item.userName !== null &&
                    item.userName !== "" &&
                    item.userName.toLowerCase().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))) ||
                (item.mobileType !== null &&
                    item.mobileType !== "" &&
                    item.mobileType.toLowerCase().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))) ||
                (item.tripPlanType !== null &&
                    item.tripPlanType !== "" &&
                    item.tripPlanType.toLowerCase().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))) ||
                (item.userQuery !== null &&
                    item.userQuery !== "" &&
                    item.userQuery.toLowerCase().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))) ||
                (item.overallResponseTime !== null &&
                    item.overallResponseTime !== "" &&
                    item.overallResponseTime.split(" ").join("").includes(searchText.split(" ").join(""))) ||
                (item.mobileType !== null &&
                    item.mobileType !== "" &&
                    item.mobileType.toLowerCase().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))) ||
                (item.status !== null &&
                    item.status !== "" &&
                    item.status.toLowerCase().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))) ||
                formattedCreatDate.toLowerCase().split(" ").join("").includes(searchText.toLowerCase().split(" ").join(""))
            );
        });

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPageS, setRowsPerPage] = useState(7);

    //Dropdown
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Last Week");
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleDropdownChange = (value) => {
        setCurrentPage(1);
        setSelectedOption(value);
        setIsOpen(false);
        const today = new Date();
        let startDate = new Date();
        let endDate = new Date();

        switch (value) {
            case "Today":
                startDate = today;
                endDate = today;
                break;
            case "Last Week":
                startDate.setDate(today.getDate() - 7);
                break;
            case "Last Month":
                startDate.setMonth(today.getMonth() - 1);
                break;
            case "Last 3 Months":
                startDate.setMonth(today.getMonth() - 3);
                break;
            default:
                break;
        }
        setRangeDate({ start: startDate, end: endDate });
    };

    //Datepicker
    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - 7);

    const [rangeDate, setRangeDate] = useState({
        start: daysAgo,
        end: new Date(),
    });
    const onRangeChange = (dates) => {
        const [start, end] = dates;
        setRangeDate({ start: start, end: end });
        setCurrentPage(1);
    };
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    //Pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    //Search
    const changeSearchText = (e) => {
        setCurrentPage(1); //For filtering data even in different pages
        setSearchText(e.target.value);
    };

    const handleCheckboxClick = () => {
        setExpandData(!expandData);
    };

    const ExpandableRowComponent = ({ data }) => {

        return (
            <ul className="dtr-details p-2 border-bottom ml-1">
                <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
                    <span style={{ marginRight: "1rem" }} className=" dtr-title text-primary">
                        Query Response :
                    </span>{" "}<br/>
                    <span className="dtr-data">{(data.queryContent!==null && data.queryContent!=="") ? data.queryContent : "N/A"}</span>
                </li>
                <li style={{ padding: "0.25rem"}}>
                    <span style={{ marginRight: "1rem" }} className=" dtr-title text-primary">
                        Response URL :
                    </span>{" "}<br />
                    <span className="dtr-data">{(data.queryurl !== null && data.queryurl !== "") ? data.queryurl : "N/A"}</span>
                </li>
            </ul>
        );
    };

    //Export
    const exportSuccessMsg = () => {
        toast.dark("Chart Report Exported Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            icon: false,
        });
    };

    const exportNoDataAlert = () => {
        toast.error("There is no data available for export!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
        });
    };

    const exportCSV = () => {
        const fileName = "ZIG AI Analytics";
        if (filteredItems.length === 0) {
            exportNoDataAlert();
            return;
        }

        const formattedItems = filteredItems.map((item) => {
            return {
                ...item,
                dateTime: moment(item.dateTime).format("MMMM Do YYYY, h:mm a"),
                timestamp: moment(item.timestamp).format("MMMM Do YYYY, h:mm a"),
            };
        });

        const exportType = exportFromJSON.types.csv;

        try {
            exportFromJSON({
                data: formattedItems,
                fileName: fileName,
                exportType: exportType,
            });
            exportSuccessMsg();
        } catch (error) {
            console.error("Export failed:", error);
            exportNoDataAlert();
        }
    };
    const exportExcel = () => {
        const fileName = "ZIG AI Analytics";
        if (filteredItems.length === 0) {
            exportNoDataAlert();
            return;
        }

        const formattedItems = filteredItems.map((item) => {
            return {
                ...item,
                dateTime: moment(item.dateTime).format("MMMM Do YYYY, h:mm a"),
                timestamp: moment(item.timestamp).format("MMMM Do YYYY, h:mm a"),
            };
        });

        const exportType = exportFromJSON.types.xls;

        try {
            exportFromJSON({
                data: formattedItems,
                fileName: fileName,
                exportType: exportType,
            });
            exportSuccessMsg();
        } catch (error) {
            console.error("Export failed:", error);
            exportNoDataAlert();
        }
    };

    const exportPDF = (filteredItems) => {
        const fileName = `ZIG AI Analytics`;
        if (filteredItems.length === 0) {
            exportNoDataAlert();
            return;
        }
        const doc = new jsPDF("landscape");

        const logo = new Image();
        logo.crossOrigin = "anonymous";
        logo.src = logoUrl;

        logo.onload = () => {
            const imgWidth = 30;
            const imgHeight = 30;
            const logoX = doc.internal.pageSize.width - imgWidth - 10;
            const logoY = 2;
            doc.addImage(logo, "JPEG", logoX, logoY, imgWidth, imgHeight);

            doc.setFont("times", "bold");
            doc.setFontSize(20);
            doc.setTextColor(255, 0, 0);
            {
                userrole === 3 ? doc.text(clientValueLabel, 10, 20) : doc.text(username, 10, 20);
            }

            doc.setFont("times", "normal");
            doc.setFontSize(16);
            doc.setTextColor(0);
            doc.text("ZIG AI Analytics", 10, 40);

            const columnHeaders = ["Date", "User Name", "Mobile Type", "Query", "Assistant Type", "Response Time", "Status"];
            const tableData = filteredItems.map((item) => [
                item.timestamp !== null && item.timestamp !== "" ? moment(item.timestamp).format("MMMM Do YYYY, h:mm a") : "-",
                // item.userId !== null && item.userId !== "" ? item.userId : "-",
                item.userName !== null && item.userName !== "" ? item.userName : "-",
                item.mobileType !== null && item.mobileType !== "" ? item.mobileType : "-",
                item.userQuery !== null && item.userQuery !== "" ? item.userQuery : "-",
                item.tripPlanType !== null && item.tripPlanType !== "" ? item.tripPlanType : "-",
                item.overallResponseTime
                    ? item.overallResponseTime === "N/A"
                        ? item.overallResponseTime
                        : item.overallResponseTime + " sec"
                    : "-",
                // item.mobileType !== null && item.mobileType !== "" ? item.mobileType : "-",
                item.status !== null && item.status !== "" ? (item.status.toLowerCase() === "pass" ? "Success" : (item.status.toLowerCase() === "fail" ? "Failure" : item.status)) : "-",
            ]);
            doc.autoTable({
                head: [columnHeaders],
                body: tableData,
                startY: 50,
                columnStyles: {
                    0: { cellWidth: 60 },
                    1: { cellWidth: 50 },
                    4: { cellWidth: 40 },
                },
            });
            doc.save(fileName + ".pdf");
            exportSuccessMsg();
        };

        logo.onerror = () => {
            console.error("Failed to load logo image");

            const columnHeaders = ["Date", "User Name", "Query", "Response Time", "Status"];
            const tableData = filteredItems.map((item) => [
                item.timestamp !== null && item.timestamp !== "" ? moment(item.timestamp).format("MMMM Do YYYY, h:mm a") : "-",
                // item.userId !== null && item.userId !== "" ? item.userId : "-",
                item.userName !== null && item.userName !== "" ? item.userName : "-",
                item.userQuery !== null && item.userQuery !== "" ? item.userQuery : "-",
                item.overallResponseTime
                    ? item.overallResponseTime === "N/A"
                        ? item.overallResponseTime
                        : item.overallResponseTime + " sec"
                    : "-",
                // item.mobileType !== null && item.mobileType !== "" ? item.mobileType : "-",
                item.status !== null && item.status !== "" ? item.status : "-",
            ]);
            doc.autoTable({
                head: [columnHeaders],
                body: tableData,
                columnStyles: {
                    2: { cellWidth: 70 },
                },
            });
            doc.save(fileName + ".pdf");
            exportSuccessMsg();
        };
    };

    const ResetFilter = () => {
        setAndroidChecked(false);
        setIosChecked(false);
        setWebChecked(false);
        setSelectedAssisType(null);
        setSelectedVersion(null);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (rangeDate.start && rangeDate.end) {
                    const response = await axios.get(
                        `${process.env.REACT_APP_CONFIG_BASE_URL}ZigAIAPI/zigaiAnalytics/log?startDate=${formatDate(
                            rangeDate.start
                        )}&endDate=${formatDate(rangeDate.end)}`
                    );

                    const sortedData = response.data.sort((a, b) => b.timestamp - a.timestamp);

                    setData(sortedData);

                    const uniqueCities = new Set(sortedData.map((item) => item.userCity).filter((city) => city));
                    //Android
                    const uniqueVersionsAndroid = new Set(
                        sortedData
                            .filter((item) => item.mobileType.toLowerCase() === "android" && item.appVersion)
                            .map((item) => item.appVersion)
                    );
                    const versionObjectsAndroid = Array.from(uniqueVersionsAndroid).map(version => ({
                        value: version,
                        label: version,
                    }));

                    //iOS
                    const uniqueVersionsiOS = new Set(
                        sortedData
                            .filter((item) => item.mobileType.toLowerCase() === "ios" && item.appVersion)
                            .map((item) => item.appVersion)
                    );
                    const versionObjectsiOS = Array.from(uniqueVersionsiOS).map(version => ({
                        value: version,
                        label: version,
                    }));

                    //Web
                    const uniqueVersionsWeb = new Set(
                        sortedData
                            .filter((item) => item.mobileType.toLowerCase() === "web" && item.appVersion)
                            .map((item) => item.appVersion)
                    );
                    const versionObjectsWeb = Array.from(uniqueVersionsWeb).map(version => ({
                        value: version,
                        label: version,
                    }));

                    setCities(Array.from(uniqueCities));
                    setAppVersionsAndroid(versionObjectsAndroid);
                    setAppVersionsiOS(versionObjectsiOS);
                    setAppVersionsWeb(versionObjectsWeb);

                    if (hasSetAppVersions.current) {
                        setAppVersions([...versionObjectsAndroid, ...versionObjectsiOS, ...versionObjectsWeb]);
                        hasSetAppVersions.current = false;
                    }

                }
            } catch (err) {
                console.log("error", err);
                setData([]);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [selectedOption, rangeDate.end]);

    useEffect(() => {

        if (!androidChecked && !iosChecked && !webChecked) {
            setAppVersions([...appVersionsAndroid, ...appVersionsiOS, ...appVersionsWeb]);
        }
        else if (androidChecked && iosChecked && webChecked) {
            setAppVersions([...appVersionsAndroid, ...appVersionsiOS, ...appVersionsWeb]);
        }
        else if (androidChecked && iosChecked) {
            setAppVersions([...appVersionsAndroid, ...appVersionsiOS]);
        }
        else if (androidChecked && webChecked) {
            setAppVersions([...appVersionsAndroid, ...appVersionsWeb]);
        }
        else if (iosChecked && webChecked) {
            setAppVersions([...appVersionsiOS, ...appVersionsWeb]);
        }
        else if (androidChecked) {
            setAppVersions([...appVersionsAndroid]);
        }
        else if (iosChecked) {
            setAppVersions([...appVersionsiOS]);
        }
        else if (webChecked) {
            setAppVersions([...appVersionsWeb]);
        }
        else {
            setAppVersions([...appVersionsAndroid, ...appVersionsiOS, ...appVersionsWeb]);
        }

    }, [androidChecked, iosChecked, webChecked, data]);


    useEffect(() => {

        //Filter by city and mobile type
        let filterOneData;
        if (selectedCity === "All") {
            if (androidChecked && iosChecked && webChecked) {
                filterOneData = data;
            }
            else if (androidChecked && iosChecked) {
                const filteredData = data.filter((item) => {
                    return item.mobileType.toLowerCase() === "android" || item.mobileType.toLowerCase() === "ios";
                });
                filterOneData = filteredData;
            }
            else if (androidChecked && webChecked) {
                const filteredData = data.filter((item) => {
                    return item.mobileType.toLowerCase() === "android" || item.mobileType.toLowerCase() === "web";
                });
                filterOneData = filteredData;
            }
            else if (iosChecked && webChecked) {
                const filteredData = data.filter((item) => {
                    return item.mobileType.toLowerCase() === "ios" || item.mobileType.toLowerCase() === "web";
                });
                filterOneData = filteredData;
            }
            else if (androidChecked || iosChecked || webChecked) {
                const filteredData = data.filter((item) => {
                    if (androidChecked) {
                        return item.mobileType.toLowerCase() === "android";
                    } else if (iosChecked) {
                        return item.mobileType.toLowerCase() === "ios";
                    } else if (webChecked) {
                        return item.mobileType.toLowerCase() === "web";
                    }
                });
                filterOneData = filteredData;
            }
            else {
                filterOneData = data;
            }
        } else {
            if (androidChecked && iosChecked && webChecked) {
                const filteredData = data.filter((item) => {
                    return item.userCity === selectedCity;
                })
                filterOneData = filteredData;
            }
            else if (androidChecked && iosChecked) {
                const filteredData = data.filter((item) => {
                    return (item.mobileType.toLowerCase() === "android" || item.mobileType.toLowerCase() === "ios") && item.userCity === selectedCity;
                });
                filterOneData = filteredData;
            }
            else if (androidChecked && webChecked) {
                const filteredData = data.filter((item) => {
                    return (item.mobileType.toLowerCase() === "android" || item.mobileType.toLowerCase() === "web") && item.userCity === selectedCity;
                });
                console.log("filteredData: ", filteredData);
                filterOneData = filteredData;
            }
            else if (iosChecked && webChecked) {
                const filteredData = data.filter((item) => {
                    return (item.mobileType.toLowerCase() === "ios" || item.mobileType.toLowerCase() === "web") && item.userCity === selectedCity;
                });
                filterOneData = filteredData;
            }
            else if (androidChecked || iosChecked || webChecked) {
                const filteredData = data.filter((item) => {
                    if (androidChecked) {
                        return item.mobileType.toLowerCase() === "android" && item.userCity === selectedCity;
                    } else if (iosChecked) {
                        return item.mobileType.toLowerCase() === "ios" && item.userCity === selectedCity;
                    } else if (webChecked) {
                        return item.mobileType.toLowerCase() === "web" && item.userCity === selectedCity;
                    }
                });
                console.log("Seperate data: ", filteredData);
                filterOneData = filteredData;
            }
            else {
                const filteredData = data.filter((item) => {
                    return item.userCity === selectedCity;
                })
                filterOneData = filteredData;
            }
        }

        console.log("filterOneData: ", filterOneData);

        //Filter by Assistant type
        let filterTwoData;
        if (selectedAssisType) {
            const filteredData = filterOneData.filter((item) => {
                return item.tripPlanType && item.tripPlanType.toLowerCase() === selectedAssisType.value;
            })
            filterTwoData = filteredData;
        }
        else {
            filterTwoData = filterOneData;
        }

        console.log("filterTwoData: ", filterTwoData);

        let filterThreeData
        if (selectedVersion) {
            console.log("selectedVersion: ", selectedVersion.value);
            const filteredData = filterTwoData.filter((item) => {
                return item.appVersion && item.appVersion == selectedVersion.value;
            })
            console.log("filteredData: ", filteredData);
            filterThreeData = filteredData;
        }
        else {
            filterThreeData = filterTwoData;
        }

        console.log("filterThreeData: ", filterThreeData);

        setDisplayData(filterThreeData);

    }, [data, selectedCity, androidChecked, iosChecked, webChecked, selectedAssisType, selectedVersion]);

    useEffect(() => {
        setSelectedVersion(null);
    }, [androidChecked, iosChecked, webChecked]);

    useEffect(() => {
        //Summary Info
        const totalCnt = filteredItems.length;
        const AIcnt = filteredItems.filter((item) => item.tripPlanType !== null && item.tripPlanType.toLowerCase() === "ai assistant").length;
        const guideCnt = filteredItems.filter((item) => item.tripPlanType !== null && item.tripPlanType.toLowerCase() === "guide assistant").length;
        const tripCnt = filteredItems.filter((item) => item.tripPlanType !== null && item.tripPlanType.toLowerCase() === "trip suggestions").length;
        const othersCnt = totalCnt - AIcnt - guideCnt - tripCnt;

        setTotalCnt(totalCnt);
        setAIAssistCnt(AIcnt);
        setGuideAssistCnt(guideCnt);
        setTripSuggestCnt(tripCnt);
        setOthersCnt(othersCnt)

        //Chart Info
        const androidCnt = filteredItems.filter((item) => item.mobileType.toLowerCase() === "android").length;
        const iosCnt = filteredItems.filter((item) => item.mobileType.toLowerCase() === "ios").length;
        const webCnt = filteredItems.filter((item) => item.mobileType.toLowerCase() === "web").length;
        const mobileOthers = filteredItems.length - androidCnt - iosCnt - webCnt;

        const successCnt = filteredItems.filter((item) => item.status.toLowerCase() === "pass").length;
        const failureCnt = filteredItems.filter((item) => item.status.toLowerCase() === "fail").length;
        const abortedCnt = filteredItems.filter((item) => item.status.toLowerCase() === "aborted").length;
        const incompleteCnt = filteredItems.filter((item) => item.status.toLowerCase() === "incomplete").length;
        const statusOthersCnt = filteredItems.length - successCnt - failureCnt - abortedCnt - incompleteCnt;

        if (androidCnt === 0 && iosCnt === 0 && webCnt === 0 && mobileOthers === 0) {
            setMobileTypeChart([]);
        }
        else {
            setMobileTypeChart([
                {
                    id: 'Android',
                    label: 'Android',
                    value: androidCnt
                },
                {
                    id: 'iOS',
                    label: 'iOS',
                    value: iosCnt
                },
                {
                    id: 'Web',
                    label: 'Web',
                    value: webCnt
                },
                {
                    id: 'Others',
                    label: 'Others',
                    value: mobileOthers
                }
            ])
        }

        if (successCnt === 0 && failureCnt === 0 && abortedCnt === 0 && incompleteCnt === 0 && statusOthersCnt === 0) {
            setStatusChart([]);
        }
        else {
            setStatusChart([
                {
                    id: 'Success',
                    label: 'Success',
                    value: successCnt
                },
                {
                    id: 'Failure',
                    label: 'Failure',
                    value: failureCnt
                },
                {
                    id: 'Aborted',
                    label: 'Aborted',
                    value: abortedCnt
                },
                {
                    id: 'Incomplete',
                    label: 'Incomplete',
                    value: incompleteCnt
                },
                {
                    id: 'Others',
                    label: 'Others',
                    value: statusOthersCnt
                }
            ])
        }

    }, [displayData, searchText]);

    useEffect(() => {
        if (initialRef.current) {
            initialRef.current = false;
            return;
        }

        //Update dropdown
        if (cityRef.current) {
            cityRef.current.toggle();
        }
    }, [selectedCity]);


    return (
        <React.Fragment>
            <Head title="Homepage"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page tag="h3">
                                ZIG AI Analytics
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <div className="flex-grow-1" style={{ marginTop: "-10px" }}>
                        <div className=" mb-2" style={{ border: "1px solid #dee2e6" }}></div>
                        <Row className={"mb-2"}>
                            <Col sm="12" md="4" >
                                <Card>
                                    <div style={{ backgroundColor: "lightblue", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                        <h6 style={{ margin: "12px" }}>Assistant Type</h6>
                                    </div>

                                    {
                                        isLoading ?
                                            <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Spinner type="grow" size="sm" color="primary" />
                                            </div> :
                                            <div style={{ height: "200px" }}>
                                                <div className="d-flex align-items-center justify-content-between my-2 mx-4">
                                                    <span style={{ fontSize: "16px" }}><Icon name="list-thumb" className="bg-primary-dim mr-2 p-1 rounded" />Total</span>
                                                    <h6 className="text-primary">{totalCnt}</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between my-2 mx-4">
                                                    <span style={{ fontSize: "16px" }}><Icon name="sign-ada or network" className="bg-pink-dim mr-2 p-1 rounded" />AI Assistant</span>
                                                    <h6 className="text-pink">{AIAssistCnt}</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between my-2 mx-4">
                                                    <span style={{ fontSize: "16px" }}><Icon name="map" className="bg-danger-dim mr-2 p-1 rounded" />Guide Assistant</span>
                                                    <h6 className="text-danger">{guideAssistCnt}</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between my-2 mx-4">
                                                    <span style={{ fontSize: "16px" }}><Icon name="chat" className="bg-teal-dim mr-2 p-1 rounded" />Trip Suggestions</span>
                                                    <h6 className="text-teal">{tripSuggestCnt}</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between my-2 mx-4">
                                                    <span style={{ fontSize: "16px" }}><Icon name="shuffle" className="bg-secondary-dim mr-2 p-1 rounded" />Others</span>
                                                    <h6 className="text-secondary">{othersCnt}</h6>
                                                </div>
                                            </div>
                                    }
                                </Card>
                            </Col>
                            <Col sm="12" md="4">
                                <Card className={window.innerWidth <= 768 ? " mt-2" : ""}>
                                    <div style={{ backgroundColor: "lightblue", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                        <h6 style={{ margin: "12px" }}>Mobile Type</h6>
                                    </div>

                                    {
                                        isLoading ?
                                            <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Spinner type="grow" size="sm" color="primary" />
                                            </div> :
                                            <div style={{ height: "200px" }}>
                                                {mobileTypeChart.length > 0 ?
                                                    <ResponsivePie
                                                        colors={{ scheme: "accent" }}
                                                        data={mobileTypeChart}
                                                        margin={{ top: 20, right: 50, bottom: 30, left: 40 }}
                                                        innerRadius={0.5}
                                                        padAngle={0.7}
                                                        cornerRadius={3}
                                                        activeOuterRadiusOffset={8}
                                                        borderWidth={1}
                                                        borderColor={{
                                                            from: "color",
                                                            modifiers: [["darker", 0.2]],
                                                        }}
                                                        arcLinkLabelsSkipAngle={13}
                                                        arcLinkLabelsTextColor="#333333"
                                                        arcLinkLabelsThickness={2}
                                                        arcLinkLabelsColor={{ from: "color" }}
                                                        arcLabelsSkipAngle={10}
                                                        arcLabelsTextColor={{
                                                            from: "color",
                                                            modifiers: [["darker", 2]],
                                                        }}
                                                        defs={[
                                                            {
                                                                id: "dots",
                                                                type: "patternDots",
                                                                background: "inherit",
                                                                color: "rgba(255, 255, 255, 0.3)",
                                                                size: 4,
                                                                padding: 1,
                                                                stagger: true,
                                                            },
                                                            {
                                                                id: "lines",
                                                                type: "patternLines",
                                                                background: "inherit",
                                                                color: "rgba(255, 255, 255, 0.3)",
                                                                rotation: -45,
                                                                lineWidth: 6,
                                                                spacing: 10,
                                                            },
                                                        ]}
                                                        fill={[
                                                            {
                                                                match: {
                                                                    id: "ruby",
                                                                },
                                                                id: "dots",
                                                            },
                                                            {
                                                                match: {
                                                                    id: "c",
                                                                },
                                                                id: "dots",
                                                            },
                                                            {
                                                                match: {
                                                                    id: "go",
                                                                },
                                                                id: "dots",
                                                            },
                                                            {
                                                                match: {
                                                                    id: "python",
                                                                },
                                                                id: "dots",
                                                            },
                                                            {
                                                                match: {
                                                                    id: "scala",
                                                                },
                                                                id: "lines",
                                                            },
                                                            {
                                                                match: {
                                                                    id: "lisp",
                                                                },
                                                                id: "lines",
                                                            },
                                                            {
                                                                match: {
                                                                    id: "elixir",
                                                                },
                                                                id: "lines",
                                                            },
                                                            {
                                                                match: {
                                                                    id: "javascript",
                                                                },
                                                                id: "lines",
                                                            },
                                                        ]}
                                                    /> :
                                                    <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        No Data Available
                                                    </div>
                                                }
                                            </div>
                                    }
                                </Card>
                            </Col>
                            <Col sm="12" md="4">
                                <Card className={window.innerWidth <= 768 ? " mt-2" : ""}>
                                    <div style={{ backgroundColor: "lightblue", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                        <h6 style={{ margin: "12px" }}>Status</h6>
                                    </div>

                                    {
                                        isLoading ?
                                            <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Spinner type="grow" size="sm" color="primary" />
                                            </div> :
                                            <div style={{ height: "200px" }}>
                                                {
                                                    statusChart.length > 0 ?
                                                        <ResponsivePie
                                                            colors={{ scheme: "set2" }}
                                                            data={statusChart}
                                                            margin={{ top: 20, right: 50, bottom: 30, left: 40 }}
                                                            innerRadius={0.5}
                                                            padAngle={0.7}
                                                            cornerRadius={3}
                                                            activeOuterRadiusOffset={8}
                                                            borderWidth={1}
                                                            borderColor={{
                                                                from: "color",
                                                                modifiers: [["darker", 0.2]],
                                                            }}
                                                            arcLinkLabelsSkipAngle={13}
                                                            arcLinkLabelsTextColor="#333333"
                                                            arcLinkLabelsThickness={2}
                                                            arcLinkLabelsColor={{ from: "color" }}
                                                            arcLabelsSkipAngle={10}
                                                            arcLabelsTextColor={{
                                                                from: "color",
                                                                modifiers: [["darker", 2]],
                                                            }}
                                                            defs={[
                                                                {
                                                                    id: "dots",
                                                                    type: "patternDots",
                                                                    background: "inherit",
                                                                    color: "rgba(255, 255, 255, 0.3)",
                                                                    size: 4,
                                                                    padding: 1,
                                                                    stagger: true,
                                                                },
                                                                {
                                                                    id: "lines",
                                                                    type: "patternLines",
                                                                    background: "inherit",
                                                                    color: "rgba(255, 255, 255, 0.3)",
                                                                    rotation: -45,
                                                                    lineWidth: 6,
                                                                    spacing: 10,
                                                                },
                                                            ]}
                                                            fill={[
                                                                {
                                                                    match: {
                                                                        id: "ruby",
                                                                    },
                                                                    id: "dots",
                                                                },
                                                                {
                                                                    match: {
                                                                        id: "c",
                                                                    },
                                                                    id: "dots",
                                                                },
                                                                {
                                                                    match: {
                                                                        id: "go",
                                                                    },
                                                                    id: "dots",
                                                                },
                                                                {
                                                                    match: {
                                                                        id: "python",
                                                                    },
                                                                    id: "dots",
                                                                },
                                                                {
                                                                    match: {
                                                                        id: "scala",
                                                                    },
                                                                    id: "lines",
                                                                },
                                                                {
                                                                    match: {
                                                                        id: "lisp",
                                                                    },
                                                                    id: "lines",
                                                                },
                                                                {
                                                                    match: {
                                                                        id: "elixir",
                                                                    },
                                                                    id: "lines",
                                                                },
                                                                {
                                                                    match: {
                                                                        id: "javascript",
                                                                    },
                                                                    id: "lines",
                                                                },
                                                            ]}
                                                        /> :
                                                        <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            No Data Available
                                                        </div>
                                                }
                                            </div>
                                    }
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                {/* <label htmlFor="startDate">Date Range</label> */}
                                <div className="d-flex align-items-end" style={{ marginBottom: 10 }}>
                                    <div style={{ textAlign: "right" }}>
                                        <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="dropdown-container">
                                            <DropdownToggle
                                                color="primary"
                                                className="dropdown-toggle"
                                                style={{ paddingLeft: 20, paddingRight: 20 }}
                                            >
                                                <Icon style={{ paddingRight: "5px" }} name="calender-date" />
                                                {selectedOption}
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu">
                                                <DropdownItem onClick={() => handleDropdownChange("Today")}>Today</DropdownItem>
                                                <DropdownItem onClick={() => handleDropdownChange("Last Week")}>Last Week</DropdownItem>
                                                <DropdownItem onClick={() => handleDropdownChange("Last Month")}>Last Month</DropdownItem>
                                                <DropdownItem onClick={() => handleDropdownChange("Last 3 Months")}>Last 3 Months</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div className="ml-2" style={{ width: "200px" }}>
                                        <DatePicker
                                            selected={rangeDate.start}
                                            startDate={rangeDate.start}
                                            onChange={onRangeChange}
                                            endDate={rangeDate.end}
                                            selectsRange
                                            className="form-control date-picker"
                                            style={{ width: "200px" }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="flex-grow-1">
                        <Row>
                            <Col sm="12" md="6">
                                <div>
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <div className="form-control-wrap">
                                                <div className="form-icon form-icon-right">
                                                    <Icon name="search"></Icon>
                                                </div>
                                                <input
                                                    style={{ width: "220px" }}
                                                    type="text"
                                                    className="form-control color-danger"
                                                    id="default-04"
                                                    placeholder="Search"
                                                    // value={searchText}
                                                    onChange={(e) => changeSearchText(e)}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <UncontrolledDropdown className="pl-1" ref={cityRef}>
                                                <DropdownToggle
                                                    outline
                                                    color="secondary"
                                                    className="dropdown-toggle dropdown-indicator btn-dim-secondary flex"
                                                >
                                                    City : {selectedCity}
                                                </DropdownToggle>
                                                <DropdownMenu className="custom-dropdown-menu">
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Select City</span>
                                                        </li>
                                                        <li className={selectedCity === "All" ? "active" : ""} onClick={() => setSelectedCity("All")}>
                                                            <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                                                All
                                                            </a>
                                                        </li>
                                                        {cities.map((city, index) => (
                                                            <li
                                                                key={index}
                                                                className={selectedCity === city ? "active" : ""}
                                                                onClick={() => setSelectedCity(city)}
                                                            >
                                                                <a href="#links" onClick={(ev) => ev.preventDefault()}>
                                                                    {city}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <UncontrolledDropdown className="pl-1 d-none d-md-block">
                                                <DropdownToggle className="dropdown-toggle flex">
                                                    <div className="custom-control custom-control-sm custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            checked={expandData}
                                                            onClick={handleCheckboxClick}
                                                            className="custom-control-input form-control"
                                                            id="expand-data"
                                                        />
                                                        <label className="custom-control-label" htmlFor="expand-data">
                                                            Expand
                                                        </label>
                                                    </div>
                                                </DropdownToggle>
                                            </UncontrolledDropdown>
                                        </li>
                                        <li className="d-none d-md-block">
                                            <UncontrolledDropdown isOpen={dropdownFilter} toggle={() => setDropdownFilter(!dropdownFilter)}>
                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                    <div className="dot dot-primary"></div>
                                                    <Icon style={{ fontSize: "25px" }} name="filter-alt"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    left
                                                    className="filter-wg dropdown-menu-xl"
                                                    style={{ overflow: "visible" }}
                                                >
                                                    <div className="dropdown-head border-bottom border-secondary">
                                                        <span className="sub-title dropdown-title">Filter Info</span>
                                                        <div className="dropdown">
                                                            <a
                                                                href="#more"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                                className="btn btn-sm btn-icon"
                                                            >
                                                                <Icon style={{ fontSize: "20px" }} onClick={() => setDropdownFilter(false)} name="cross-circle"></Icon>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown-body dropdown-body-rg">
                                                        <Row className="gx-6 gy-3">
                                                            <Col size="4">
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input form-control"
                                                                        id="android"
                                                                        checked={androidChecked}
                                                                        onChange={() => setAndroidChecked(!androidChecked)}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="android">
                                                                        {" "}
                                                                        Android
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col size="4">
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input form-control"
                                                                        id="iOS"
                                                                        checked={iosChecked}
                                                                        onChange={() => setIosChecked(!iosChecked)}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="iOS">
                                                                        {" "}
                                                                        iOS
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col size="4">
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input form-control"
                                                                        id="web"
                                                                        checked={webChecked}
                                                                        onChange={() => setWebChecked(!webChecked)}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="web">
                                                                        {" "}
                                                                        Web
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col size="6">
                                                                <FormGroup>
                                                                    <label className="overline-title overline-title-alt text-primary">App Version</label>
                                                                    <RSelect value={selectedVersion} onChange={(value) => setSelectedVersion(value)} options={appVersions} placeholder="Select Version" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col size="6">
                                                                <FormGroup>
                                                                    <label className="overline-title overline-title-alt text-primary">Assistant Type</label>
                                                                    <RSelect value={selectedAssisType} onChange={(value) => setSelectedAssisType(value)} options={assisTypes} placeholder="Select Type" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="dropdown-foot start">
                                                        <a
                                                            href="#reset"
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                                ResetFilter();
                                                            }}
                                                            className="clickable d-flex align-items-center"
                                                            style={{ fontSize: "14px" }}
                                                        >
                                                            <span className="mr-1"><Icon name="reload" /></span>
                                                            Reset
                                                        </a>
                                                    </div>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm="12" md="6">
                                <div className="d-flex justify-content-md-end">
                                    <div className="dt-export-buttons d-flex align-center">
                                        <div className="dt-export-title d-none d-md-inline-block">Export</div>
                                        <div className="dt-buttons btn-group flex-wrap">
                                            <button
                                                className="btn btn-secondary buttons-csv buttons-html5"
                                                type="button"
                                                onClick={() => exportCSV(filteredItems)}
                                            >
                                                <span>CSV</span>
                                            </button>{" "}
                                            <button
                                                className="btn btn-secondary buttons-excel buttons-html5"
                                                type="button"
                                                onClick={() => exportExcel(filteredItems)}
                                            >
                                                <span>Excel</span>
                                            </button>{" "}
                                            <button
                                                className="btn btn-secondary buttons-pdf buttons-html5"
                                                type="button"
                                                onClick={() => exportPDF(filteredItems)}
                                            >
                                                <span>PDF</span>
                                            </button>{" "}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="flex-grow-1">
                            <div>
                                <ul className="nk-block-tools g-3">
                                    <li>
                                        <div className="form-control-wrap">
                                            <div className="form-icon form-icon-right">
                                                <Icon name="search"></Icon>
                                            </div>
                                            <input
                                                style={{ width: "220px" }}
                                                type="text"
                                                className="form-control color-danger"
                                                id="default-04"
                                                placeholder="Search"
                                                // value={searchText}
                                                onChange={(e) => changeSearchText(e)}
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="dt-export-buttons d-flex align-center">
                            <div className="dt-export-title d-none d-md-inline-block">Export</div>
                            <div className="dt-buttons btn-group flex-wrap">
                                <button
                                    className="btn btn-secondary buttons-csv buttons-html5"
                                    type="button"
                                    onClick={() => exportCSV(filteredItems)}
                                >
                                    <span>CSV</span>
                                </button>{" "}
                                <button
                                    className="btn btn-secondary buttons-excel buttons-html5"
                                    type="button"
                                    onClick={() => exportExcel(filteredItems)}
                                >
                                    <span>Excel</span>
                                </button>{" "}
                                <button
                                    className="btn btn-secondary buttons-pdf buttons-html5"
                                    type="button"
                                    onClick={() => exportPDF(filteredItems)}
                                >
                                    <span>PDF</span>
                                </button>{" "}
                            </div>
                        </div>
                    </div> */}
                    {isLoading ? (
                        <Card className="mt-2">
                            <div className="text-center p-4">
                                <Spinner color="primary" />
                            </div>
                        </Card>
                    ) : (
                        <>
                            <DataTable
                                data={filteredItems}
                                columns={columns}
                                noDataComponent={<div className="pt-5 pb-5 text-center">No Data Available</div>}
                                sortIcon={
                                    <div>
                                        <span>&darr;</span>
                                        <span>&uarr;</span>
                                    </div>
                                }
                                expandableRowsComponent={ExpandableRowComponent}
                                expandableRows={true}
                                expandableRowExpanded={() => expandData}
                                defaultSortField="dateTime"
                                pagination
                                paginationTotalRows={filteredItems.length}
                                paginationPerPage={rowsPerPageS}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
                                    <DataTablePagination
                                        customItemPerPage={rowsPerPageS}
                                        itemPerPage={rowsPerPage}
                                        totalItems={rowCount}
                                        paginate={onChangePage}
                                        currentPage={currentPage}
                                        onChangeRowsPerPage={onChangeRowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                    />
                                )}
                            ></DataTable>
                        </>
                    )}
                </Block>
            </Content>
        </React.Fragment>
    );
};

const columns = [
    {
        name: "Date",
        selector: (row) => row.timestamp,
        sortable: true,
        grow: 1,
        cell: (row) =>
            row.timestamp ? (
                <div style={{ fontWeight: "bold" }}>{moment(row.timestamp).format("MMM Do YYYY, h:mm a")}</div>
            ) : (
                "-"
            ),
    },
    {
        name: "User Name",
        selector: (row) => row.userName.toLowerCase(),
        sortable: true,
        grow: 1.1,
        cell: (row) => (row.userName ? row.userName : "-"),
    },
    {
        name: <div style={{ margin: "0 auto" }}>Mobile <br /> Type</div>,
        selector: (row) => row.mobileType.toLowerCase(),
        sortable: true,
        grow: 0.3,
        center: true,
        cell: (row) => (row.mobileType ? (row.mobileType.toLowerCase() === "android" ? <Badge style={{ background: "#854FFF", border: "none" }}>Android</Badge> : (row.mobileType.toLowerCase() === "ios" ? <Badge color="warning">iOS</Badge> : (row.mobileType.toLowerCase() === "web" ? <Badge color="primary">Web</Badge> : <Badge color="secondary">{row.mobileType}</Badge>))) : "-"),
    },
    {
        name: <div style={{ margin: "0 auto" }}>Query</div>,
        selector: (row) => row.userQuery.toLowerCase(),
        sortable: true,
        grow: 1,
        // center: true,
        cell: (row) => <CommentWithReadMore comment={row.userQuery ? row.userQuery : "-"} keyValue="AI" />
    },

    // {
    //     name: "Mobile Platform",
    //     selector: (row) => row.mobileType ? row.mobileType : "-",
    //     sortable: true,
    //     grow: 0.6
    // },
    {
        name: <>
            Assistant<br />
            Type
        </>,
        selector: (row) => row.tripPlanType,
        sortable: true,
        grow: 0.6,
        cell: (row) => (row.tripPlanType ? row.tripPlanType : "-"),
    },
    {
        name: (
            <>
                Response
                <br />
                Time
            </>
        ),
        selector: (row) => row.overallResponseTime,
        sortable: true,
        grow: 0.4,
        cell: (row) =>
            row.overallResponseTime
                ? row.overallResponseTime === "N/A"
                    ? row.overallResponseTime
                    : row.overallResponseTime + " sec"
                : "-",
    },
    {
        name: <div style={{ margin: "0 auto" }}>Status</div>,
        selector: (row) => row.status.toLowerCase(),
        cell: (row) => row.status ? (
            row.status.toLowerCase() === "pass" ? (<Badge pill style={{ background: "#379777", border: "none" }} >Success</Badge>) : (row.status.toLowerCase() === "fail" ? <Badge pill color="danger">Failure</Badge> : (row.status.toLowerCase() === "aborted" ? <Badge pill color="secondary">Aborted</Badge> : <Badge pill color="gray">{row.status}</Badge>)))
            : (
                "-"
            ),
        sortable: true,
        center: true,
        grow: 0.5,
    },
    {
        name: <div style={{ margin: "0 auto" }}>More</div>,
        selector: (row) => row,
        cell: (row) => (
            <div>
                <ExpandableRowDataComponent data={row} />
            </div>
        ),
        center: true,
        grow: 0.1,
    },
];

export default ZIGAIAnalytics;
