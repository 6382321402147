import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { TrafficSourcesChart } from "../../charts/fleet/Chart";
import { calculateDeviceModeBadge } from "../../../../pages/FleetTracker";

const FleetTrackingChart = (device) => {
  const [data, setData] = useState("7");
  const [moving, setMoving] = useState(0);
  const [idle, setIdle] = useState(0);
  const [parked, setParked] = useState(0);
  const [offline, setOffline] = useState(0);

  useEffect(() => {
    // Calculate counts when device data changes
    const deviceData = device.device;
    const movingCount = deviceData.filter((i) => calculateDeviceModeBadge(i) === "MOVING").length;
    const idleCount = deviceData.filter((i) => calculateDeviceModeBadge(i) === "IDLE").length;
    const parkedCount = deviceData.filter((i) => calculateDeviceModeBadge(i) === "PARKED").length;
    const offlineCount = deviceData.filter((i) => calculateDeviceModeBadge(i) === "OFFLINE").length;

    setMoving(movingCount);
    setIdle(idleCount);
    setParked(parkedCount);
    setOffline(offlineCount);
  }, [device.device]);

  const chartData = [moving, idle, parked, offline];

  return (
    <Card className="card-full overflow-hidden" style={{ width: "100%", height: "350px" }}>
      <div className="nk-ecwg nk-ecwg4">
        <div className="card-inner flex-grow-1">
          <div
            className="nk-ck-sm"
            style={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TrafficSourcesChart chartData={chartData} />
          </div>
          <ul className="nk-ecwg4-legends">
            <li>
              <div className="title">
                <span className="dot dot-lg sq" style={{ background: "#1ee0ac" }}></span>
                <span>Vehicles Running</span>
              </div>
              <div className="amount amount-xs">{moving}</div>
            </li>
            <li>
              <div className="title">
                <span className="dot dot-lg sq" style={{ background: "#364a63" }}></span>
                <span>Vehicles Idle</span>
              </div>
              <div className="amount amount-xs">{idle}</div>
            </li>
            <li>
              <div className="title">
                <span className="dot dot-lg sq" style={{ background: "#f4bd0e" }}></span>
                <span>Vehicles Parked</span>
              </div>
              <div className="amount amount-xs">{parked}</div>
            </li>
            <li>
              <div className="title">
                <span className="dot dot-lg sq" style={{ background: "#e85347" }}></span>
                <span>Vehicles Offline</span>
              </div>
              <div className="amount amount-xs">{offline}</div>
            </li>
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default FleetTrackingChart;
