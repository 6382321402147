import React, { useState, useEffect } from "react";

function TimeDifference({ timestamp }) {
  const [difference, setDifference] = useState("");

  useEffect(() => {
    if (!timestamp) {
      setDifference(" 0 seconds ago");
      return;
    }
    const intervalId = setInterval(() => {
      const now = new Date();
      const timestampDate = new Date(timestamp.replace(" ", "T") + "Z");
      const diffInMilliseconds = now - timestampDate;
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

      // Calculate hours, minutes, and seconds
      const hours = Math.floor(diffInSeconds / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);
      const seconds = diffInSeconds % 60;

      // Construct the time difference string based on the calculated values
      let timeDifference = "";
      if (hours > 0) {
        timeDifference += `${hours} hour${hours !== 1 ? "s" : ""} ago`;
      } else if (minutes > 0) {
        timeDifference += `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
      } else {
        const value = seconds ? `${seconds} second${seconds !== 1 ? "s" : ""} ago` : " 0 seconds ago";
        timeDifference += value;
      }

      setDifference(timeDifference);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timestamp]);

  return <span>{difference}</span>;
}

export default TimeDifference;
